<div *ngIf="totalTiles() >= 3" class="home-tiles flexbox flex-wrap space-top-xl space-bottom-xl" [ngClass]="{ 'gap-md': totalTiles() <= 3, 'gap-sm': totalTiles() > 3 }">
    <a *ngFor="let tile of tilesArr" class="tile" (click)="sendAnalytics(tile.module_id)" [attr.href]="tile.link" [attr.target]="tile.module_id === 'recruit' ? '_blank' : null" [ngClass]="{ 'clickable': tile.link, 'tile-layout': totalTiles() > 3, 'five-per-line': totalTiles() > 5 }">
        <div *ngIf="tile.module_id !== 'recruit'; else recruit_logo" class="avatar" [ngClass]="{ 'primary': !tile.static }">
            <i class="icn-{{ tile.icon }}"></i>
        </div>
        <ng-template #recruit_logo>
            <div class="tile-logo space-right-md">
                <img src="/si_ei/images/recruit-logo-updated.svg" alt="Recruit by Symplicity">
            </div>
        </ng-template>
        <div class="tile-body">
            <h2 class="tile-title">{{ tile.module }}</h2>
            <div class="tile-text text-truncate-3">{{ tile.description }}</div>
        </div>
    </a>
</div>
