import { Injectable } from "@angular/core";
import { ValkyrieAuthService } from '@symplicity/syng';
import { BehaviorSubject } from 'rxjs';
import { DateFormatPipe, IsAfterPipe, IsBeforePipe } from 'ngx-moment';
import {TranslateService} from '@ngx-translate/core';
import * as momentTimezone from 'moment-timezone';
declare var window:any;

const moment = momentTimezone;

@Injectable({ providedIn: 'root' })

export class EventDateService {
    public langDomain:string = 'career_fair students';
    public cfStatus = new BehaviorSubject(null);
    public timezone: string = '';
    public locale: string = 'en-US';
    public isVCFairLive$ = new BehaviorSubject(null)

    constructor (
      private authService: ValkyrieAuthService,
      public dateFormatPipe: DateFormatPipe,
      public isAfterPipe: IsAfterPipe,
      public isBeforePipe: IsBeforePipe,
      private translate: TranslateService,
    ) {
        this.authService.currentUser.subscribe(res => {
            if (res) {
                this.timezone = res.timezone;
            }
        });
    }

    getDateType() {
        let defaultDate = 'MMM D';
        let defaultFullDate = 'MMM D, YYYY';
        let defaultTime = 'h:mm A';

        if (window.DATETIME_FORMAT && window.DATETIME_FORMAT.DATE.MEDIUM) {
            defaultDate = window.DATETIME_FORMAT.DATE.MEDIUM.trim();
            defaultFullDate = this.convertDateFormatPhpToJs(defaultDate);
            defaultDate = defaultFullDate.replace(', YYYY', '');
            defaultDate = defaultDate.replace(' YYYY', '');
            defaultDate = defaultDate.replace(' N', '');

            if (defaultDate.indexOf('[') !== -1) {
                defaultDate = defaultDate.replace(/MMM \[(.*?)\]/, '') + 'MMM';
            }
        }

        if (window.DATETIME_FORMAT && window.DATETIME_FORMAT.TIME.SHORT) {
            defaultTime = window.DATETIME_FORMAT.TIME.SHORT.trim();
            defaultTime = defaultTime.replace('a', 'A');
        }

        let formats = {
            date: defaultDate,
            dateTime: defaultDate + ' ' + defaultTime,
            time: defaultTime,
            fullDate: defaultFullDate
        }
        return formats;
    }

    getConfigDateFormat(dateFormat?, stripYear?) {
        let format = dateFormat ? dateFormat : 'MEDIUM';
        let noYear = stripYear ? stripYear : false;
        let defaultDate = 'MMM D, YYYY';

        if (format == 'MEDIUM') {
            if (window.DATETIME_FORMAT && window.DATETIME_FORMAT.DATE.MEDIUM) {
                defaultDate = window.DATETIME_FORMAT.DATE.MEDIUM;
                defaultDate = defaultDate.replace(/\'(.*?)\'/g, "[$1]");
                defaultDate = this.convertDateFormatPhpToJs(defaultDate);
            }
        }
        if (noYear) {
            defaultDate = defaultDate.replace(', YYYY', '');
            defaultDate = defaultDate.replace(' YYYY', '');
            defaultDate = defaultDate.replace(' N', '');
        }
        return defaultDate;
    }

    getConfigTimeFormat(timeFormat?) {
        let format = timeFormat ? timeFormat : 'SHORT';
        let defaultTime = 'h:mm A';

        if (format == 'SHORT') {
            if (window.DATETIME_FORMAT && window.DATETIME_FORMAT.TIME.SHORT) {
                defaultTime = window.DATETIME_FORMAT.TIME.SHORT;
            }
        }
        return defaultTime;
    }

    convertDateFormatPhpToJs(dateFormat) {
        dateFormat = dateFormat.replace(/\'(.*?)\'/g, "[$1]");
        dateFormat = dateFormat.replace('d', 'D');
        dateFormat = dateFormat.replace('G', 'N');
        dateFormat = dateFormat.replace('y', 'YYYY');
        return dateFormat;
    }

    messages() {
        return {
            NOW_UNTIL_TIME: this.langDomain + '.Now until time',
            TODAY_AT_TIME: this.langDomain + '.Today at time',
            TODAY_START_TIME_END_TIME: this.langDomain + '.Today start time through end time',
            PLUS_X_MORE_DAYS: this.langDomain + '.+x more days',
            DATE_TO_DATE: 'date_to_date',
            TIME_TO_DATE: 'time_to_date',
            DATES_NOT_PUBLISHED: this.langDomain + '.DATES_NOT_PUBLISHED',
            ATTENDING: this.langDomain + '.Attending Time',
            ATTENDED: this.langDomain + '.Attended Time'
        };
    }

    getRangeDateGroupFormat(item: EventDate, isPast = false, convertTimezone = true) {
        const message: DateLabel = new DateLabel();
        const data: any = {}
        let sortStartDates = [...this.sortDates('start',true, item.dates)];
        let sortEndDates = [...this.sortDates('end',false, item.dates)];

        data.now = new Date().toISOString();
        data.entireEventSchedule = {
            start: sortStartDates[0]?.start,
            end: sortEndDates[0]?.end
        }
        data.dateFormat = this.getDateFormat(data, isPast);
        message.label = this.isSame(data.entireEventSchedule.start, data.entireEventSchedule.end, this.getDateType().fullDate) ? this.convertDate(data.entireEventSchedule.start, data.dateFormat, convertTimezone) : this.getDateMessage(this.messages().DATE_TO_DATE, data, convertTimezone);

        return message;
    }

    public getUpcomingEventDate(item: EventDate, includeHTML = false) {
        const data = this.generateRefData(item);
        const message = new DateLabel();
        const wrapperStart = includeHTML ? ' <span class="font-weight-normal">' : ' ';
        const wrapperEnd = includeHTML ? '</span>' : ' ';

        if (!data.eventDates.length) {
            return message;
        }

        message.isToday = !!data.todayEvent;

        if (data.isOneDayEvent) {
            if (data.todayEvent) {
                if (data.todayEvent.isStarted) {
                    message.label = this.getDateMessage(this.messages().NOW_UNTIL_TIME, data);
                } else {
                    message.label = this.getDateMessage(this.messages().TODAY_START_TIME_END_TIME, data);
                }
            } else {
                message.label = this.getDateMessage(this.messages().TIME_TO_DATE, data);
            }
        } else {
            if (data.todayEvent) {
                if (data.todayEvent.isStarted) {
                    message.label = this.getDateMessage(this.messages().NOW_UNTIL_TIME, data);
                } else {
                    message.label = this.getDateMessage(this.messages().TODAY_AT_TIME, data);
                }
                message.label += wrapperStart + this.getDateMessage(this.messages().PLUS_X_MORE_DAYS, data) + wrapperEnd;
            } else {
                message.label = this.getDateMessage(this.messages().DATE_TO_DATE, data);
            }
        }

        return message;
    }

    sortDates(target = 'start', asc = true, src) {
        return src.sort((a, b) => {
            let val = 0;
            if (this.isBeforePipe.transform(a[target], b[target]) === asc) {
                val = -1;
            } else if (this.isAfterPipe.transform(a[target], b[target]) === asc) {
                val = 1;
            }
            return val;
        });
    };

    getPastEventDate(item: EventDate) {
        return this.getRangeDateGroupFormat(item, true);
    }

    getHiddenDaysFormat()
    {
        return {
            label: this.translate.instant(this.messages().DATES_NOT_PUBLISHED),
            isToday: false
        }
    }

    private getDateMessage(type, eventRef: EventRef, convertTimezone = true): string {
        switch(type) {
            case this.messages().NOW_UNTIL_TIME:
                return this.translate.instant(type, { time: this.convertDate(eventRef.todayEvent!.date.end, this.getDateType().time, convertTimezone) });
            case this.messages().TODAY_AT_TIME:
                return this.translate.instant(type, { time: this.convertDate(eventRef.todayEvent!.date.start, this.getDateType().time, convertTimezone)});
            case this.messages().PLUS_X_MORE_DAYS:
                return this.translate.instant(type, { count: eventRef.fullDaysLeft });
            case this.messages().TODAY_START_TIME_END_TIME:
                return this.translate.instant(type, { startTime: this.convertDate(eventRef.todayEvent!.date.start, this.getDateType().time, convertTimezone), endTime: this.convertDate(eventRef.todayEvent!.date.end, this.getDateType().time, convertTimezone) });
            case this.messages().TIME_TO_DATE:
                return this.convertDate(eventRef.entireEventSchedule!.start, `${eventRef.dateFormat} ${this.getDateType().time}`, convertTimezone) + ' - ' + this.convertDate(eventRef.entireEventSchedule!.end, this.getDateType().time, convertTimezone);
            case this.messages().DATE_TO_DATE:
                return this.convertDate(eventRef.entireEventSchedule!.start, eventRef.dateFormat, convertTimezone) + ' - ' + this.convertDate(eventRef.entireEventSchedule!.end, eventRef.dateFormat, convertTimezone);
        }
        return '';
    }

    generateRefData(item: EventDate): EventRef {
        const ref: EventRef = { eventDates: item.dates };
        const now = new Date().toISOString();

        if (!item.isVirtualFair) {
            ref.eventDates = item.dates.filter(date => !moment(date.end).isBefore(now, 'seconds'));
        }
        if (ref.eventDates.length) {
            ref.entireEventSchedule = {
                start: this.sortDates('start', true, ref.eventDates)[0].start,
                end: this.sortDates('end', false, ref.eventDates)[0].end
            };

            const isDifferentYear = (a, b) => !this.toUserTimezone(a).isSame(b, 'year');
            ref.dateFormat = isDifferentYear(now, ref.entireEventSchedule.start) || isDifferentYear(now, ref.entireEventSchedule.end)
                ? this.getDateType().fullDate
                : this.getDateType().date;

            ref.isOneDayEvent = this.isSame(ref.entireEventSchedule.start, ref.entireEventSchedule.end, this.getDateType().fullDate);

            if (!ref.isOneDayEvent) {
                ref.dateFormat = this.getDateType().dateTime;
            }

            const isToday = ref.eventDates.some(
                date => this.isBetween(now, date.start, date.end, this.getDateType().fullDate)
            );
            if (isToday) {
                const todaysDates = ref.eventDates.filter(
                    date => this.isBetween(now, date.start, date.end, this.getDateType().fullDate)
                );
                const todaysFullSchedule = {
                    start: this.sortDates('start', true, todaysDates)[0].start,
                    end: this.sortDates('end', false, todaysDates)[0].end
                };
                const isStarted = ref.eventDates.some(
                    date => this.isBetween(now, date.start, date.end, this.getDateType().dateTime)
                );
                ref.todayEvent = {
                    date: todaysFullSchedule,
                    isStarted,
                };
                if (!ref.isOneDayEvent) {
                    ref.fullDaysLeft = this.calculateEventDaysLeft(now, ref.eventDates);
                }
            }
        }

        return ref;
    }

    private calculateEventDaysLeft(today: string, dates: Date[]): number {
        const eventDays = dates.reduce<moment.Moment[]>(
            (acc, date) => [...acc, this.toUserTimezone(date.start), this.toUserTimezone(date.end)],
            [],
        );
        const upcomingDays = eventDays.filter(day => day.isAfter(today, 'day'));
        const uniqueUpcomingDays = new Set(upcomingDays.map(day => day.format('YYYY-MM-DD')));
        return uniqueUpcomingDays.size;
    }

    convertDate(date, type, convertTimezone = true) {
        let newDate = convertTimezone ? this.toUserTimezone(date) : new Date(date);
        return convertTimezone ? this.dateFormatPipe.transform(newDate, type) : moment.utc(newDate).format(type);
    }

    private toUserTimezone(date) {
        const newDate = moment(date, moment.ISO_8601);
        return moment.tz(newDate, this.timezone || window.USER_TZ);
    }

    public isSame(val_A: string, val_B: string, unit?: string): boolean {
        return unit
            ? this.convertDate(val_A, unit) === this.convertDate(val_B, unit)
            : moment(val_A).isSame(val_B, 'second');
    }

    isBetween(val, start, end, unit = undefined): boolean {
        return (this.isAfterPipe.transform(val, start, unit) && this.isBeforePipe.transform(val, end, unit)) || this.isSame(val, start, unit) || this.isSame(val, end, unit);
    }

    getDateFormat(data, isPast) {
        let dateFormat = new Date(data.now).getFullYear() !== new Date(data.entireEventSchedule.start).getFullYear() || new Date(data.now).getFullYear() !== new Date(data.entireEventSchedule.end).getFullYear() || isPast ? this.getDateType().fullDate : this.getDateType().date;
        let isOneDayEvent = this.isSame(data.entireEventSchedule.start, data.entireEventSchedule.end, this.getDateType().fullDate);
        if (!isOneDayEvent) {
            dateFormat = this.getDateType().dateTime;
        }
        return dateFormat;
    }

    generateEventDateData(item) {
        let it = new EventDate({ isVirtualFair: item.type.id == CF_TYPE.CF_EVENT_TYPE_VIRTUAL_CAREER_FAIR || item.type._id == CF_TYPE.CF_EVENT_TYPE_VIRTUAL_CAREER_FAIR });
        if (it.isVirtualFair) {
            it.dates.push({ start: item.virtual_start, end: item.virtual_end });
        } else {
            item.day.forEach(d => {
                it.dates.push({ start: d.daystart, end: d.dayend })
            });
        }
        return it;
    }

    generateDateData(item) {
        let it = new EventDate({ isVirtualFair: item.type.id === CF_TYPE.CF_EVENT_TYPE_VIRTUAL_CAREER_FAIR });
        if (it.isVirtualFair) {
            it.dates.push({ start: item.days[0].event_dates.dayStart, end: item.days[item.days.length - 1].event_dates.dayEnd })
        } else {
            item.days.forEach(d => {
                it.dates.push({ start: d.event_dates.dayStart, end: d.event_dates.dayEnd })
            });
        }
        return it;
    }

    convertIsoToDate(isoFormat) {
        if (isoFormat.indexOf('T') !== -1) {
            return isoFormat.split('T')[0];
        }
        return isoFormat;
    }
}

export interface EventRef {
    eventDates: Date[];
    dateFormat?: string;
    entireEventSchedule?: Date;
    isOneDayEvent?: boolean;
    todayEvent?: {
        date: Date;
        isStarted: boolean;
    };
    fullDaysLeft?: number;
}

export interface Date {
    start: string;
    end: string;
}

export class EventDate {
    isVirtualFair: boolean = false;
    dates: Date[] = [];

    constructor(init?:Partial<EventDate>) {
        Object.assign(this, init);
    }
}

export class DateLabel {
    label: string = '';
    isToday: boolean = false;

    constructor(init?:Partial<DateLabel>) {
        Object.assign(this, init);
    }
}

export const CF_TYPE = {
    CF_EMPLOYER_ATTENDEE: '1',
    CF_STUDENT_ATTENDEE: '3',
    CF_EVENT_TYPE_CAREER_FAIR: '1',
    CF_EVENT_TYPE_STUDENT_ORIENTATION: '2',
    CF_EVENT_TYPE_VIRTUAL_CAREER_FAIR: '4'
}

export const CF_DAY_TYPE = {
    SINGLE: '1'
}

export const EVENT_TYPE = {
    ONGOING_EVENT_STATUS: 'ongoing',
    UPCOMING_EVENT_STATUS: 'upcoming',
    PASSED_EVENT_STATUS: 'passed',
    UNKNOWN_EVENT_STATUS: 'unknown',
}
