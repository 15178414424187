import { Injectable } from '@angular/core'
import { ValkyrieBackendService, ValkyrieCommonService, TranslationMenuItem, TranslationMenu } from '@symplicity/syng'
import { map } from 'rxjs/operators'
import { Observable, of, BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { STUDENT_WINDOW } from '@app/students.types';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

declare var window: STUDENT_WINDOW;

@Injectable({ providedIn: 'root' })
export class ProfileService {
    maxLength: number = 100;
    locale: string = 'en-US';
    errorMessage = new BehaviorSubject<string>('');

    constructor(
        private backendService: ValkyrieBackendService,
        private translate: TranslateService,
        private commonService: ValkyrieCommonService,
    ) {}

    setErrorMessage(message) {
        this.errorMessage.next(message);
    }

    getErrorMessage() {
        return this.errorMessage.asObservable();
    }
}
