import { EventFilters, EventRegistrationsResponse, EventsResponse } from './events.types';

export enum AttendeeTypes {
    Employer = '1',
    Student = '3'
};

export enum EventTypes {
    CareerFair = '1',
    StudentOrientation = '2',
    VirtualCareerFair = '4'
}

export const DEFAULT_PAGE = 1;

export const DEFAULT_PER_PAGE = 20;

export const PER_PAGE_VALUES = [10, 20, 50, 100, 250];

export const MESSAGES = {
    TAGS: {
        ATTENDEE_TYPES: {
            STUDENT: 'events tags.STUDENT FAIR'
        },
        EVENT_TYPES: {
            VIRTUAL_CAREER_FAIR: 'events tags.VIRTUAL'
        }
    }
};

export const EVENT_LIST_RESPONSE: EventsResponse = {
    className: 'event',
    columns: [],
    models: [],
    page: 0,
    perPage: 0,
    total: 0
};

export const EVENT_REGISTRATIONS_RESPONSE = (eventId: string): EventRegistrationsResponse => ({
    className: `event_${eventId}`,
    columns: [],
    models: [],
    page: 0,
    perPage: 0,
    total: 0
});

export const EVENT_FILTERS_RESPONSE: EventFilters = {
    fields: []
};
