<ng-container *ngIf="bannerData else bannerSkeleton;">
    <div class="hero-announcement-carousel-wrapper" *ngIf="bannerData.models.length">

        <button *ngIf="pauseButton" (click)="onPause()" class="flickity-button flickity-pause-button" [ngClass]="bannerFlickityPaused ? 'icn-play_alt' : 'icn-pause'" type="button" [attr.aria-label]="bannerFlickityPaused ? ('homepage hero_banner.Unpause' | translate) : ('homepage hero_banner.Pause' | translate)"></button>
        <div flickity #bannerFlickity
            [flickityConfig]="bannerFlickityOptions"
            (ready)="bannerReady($event)"
            (staticClick)="onStaticClick($event)"
            class="hero-announcement-carousel"
            tabindex="0"
            [intersectionObserver]="intersectionCallback"
            >
            <ng-container *ngFor="let model of bannerData.models">
                <a
                    *ngIf="model.custom_link_override; else linkModalTemplate;"
                    [attr.href]="model.custom_link_override"
                    [attr.target]="model.target" [attr.rel]="model.target === '_blank' ? 'noopener noreferrer' : ''"
                    [ngClass]="['hero-announcement-carousel-slide', model.theme]"
                    [class.use_bg]="model.use_background_overlay"
                    [ngStyle]="model.extra_styles"
                    tabindex="-1"
                    >
                    <h3>{{model.headline}}</h3>
                    <div class="hero-announcement-card-subtitle">
                        <span class="hero-announcement-card-subtitle-text">{{model.subheading}}</span>
                        <span class="hero-announcement-call-to-action">{{model.call_to_action}}</span>
                    </div>
                    <div class="hero-announcement-card-colors"></div>
                </a>
                <ng-template #linkModalTemplate>
                    <div
                        [ngClass]="['hero-announcement-carousel-slide', model.theme]"
                        [class.use_bg]="model.use_background_overlay"
                        [ngStyle]="model.extra_styles"
                        tabindex="-1"
                        >
                        <h3>{{model.headline}}</h3>
                        <div class="hero-announcement-card-subtitle">
                            <span class="hero-announcement-card-subtitle-text">{{model.subheading}}</span>
                            <span class="hero-announcement-call-to-action">{{model.call_to_action}}</span>
                        </div>
                        <div class="hero-announcement-card-colors"></div>
                    </div>
                </ng-template>
            </ng-container>
        </div>
        <span #liveRegion aria-live="polite" aria-atomic="true" class="sr-only"></span>
    </div>
</ng-container>

<ng-template #bannerSkeleton>
    <div class="skeleton-fill hero-announcement-carousel-wrapper">
        <span class="skeleton-animation"></span>
    </div>
</ng-template>
