import { Injectable } from '@angular/core';
import { ValkyrieBackendService } from '@symplicity/syng';
import { Observable } from 'rxjs';
import { pluck, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class SystemSettingService {

    private _systemSettings$:  Observable<SystemSettingsModel>;

    constructor(
        private backendService: ValkyrieBackendService,
    ) {
        this.init();
    }

    all() {
        return this._systemSettings$;
    }

    get<K extends keyof SystemSettingsModel>(ssKey: K) {
        return this._systemSettings$.pipe(
            pluck(ssKey)
        );
    }

    private init() {
        this._systemSettings$ = this.backendService.getAll('system-settings/all').pipe(
            shareReplay(1),
        )
    }
}

interface SystemSettingsModel {
    student_talent_search: number,
    graduation_month_list: string,
    hide_student_public_profile_photo: number,
    allow_students_to_modify_profile_pic: number,
    student_doc_approvals: {
        resume: string,
        'cover letter': string,
        'writing sample': string,
        'student employment': string,
        other: string
    },
    student_doc_approvals_by_doc_group: {
        [index: string]: {
            resume: string,
            'cover letter': string,
            'student employment': string
        },
    },
    use_doc_approvals_by_doc_group: number,
    student_max_majors: number,
    use_flat_major_pick: any,
    student_profilelock: string,
    student_profilepersonal_lock: string,
    student_email_editable: number,
    student_alumni_allow_email_edit: number,
    student_enable_public_profile_video: number,
    maximum_resumes: number,
    hide_psx_current_education: number,
    mail_to_contacts: number,
    skip_academic_tab: number,
    enable_psx: number,
    use_resume_bin: number,
    activate_student_document_note: number,
    law_consortia: any,
    student_display_job_leads: number,
    activate_custom_dashboards: string,
    show_employer_supports_onboarding: any,
    feedback_email: string,
    vidinterview_feedback_advisor: string,
    socketcluster_server: string,
    upcoming_events_recommendations: number,
    discovery_recommendations_homepage: number,
    events_module: {
        workshop: boolean,
        event: boolean,
        presentation: boolean
    },
    info_sess_signup_allow_overlap: number,
    kiosk_module: boolean,
    allow_later_job_edits: number,
    job_dates_instructions: string,
    student_documents_allowed: string,
    disable_autofill: number,
    sympkey_blurb: string,
    general_admin_email: string,
    external_job_feeds: string,
    school_location: {
        address_text: string,
        city: string,
        state: string,
        country: string,
        latitude: number,
        longitude: number,
        layer: string
    },
    school_name: string,
    enable_external_view_major: number,
    timespan_block_size: number,
    job_type_single: number,
    primary_mse: boolean,
    show_student_screen_criteria: number,
    allow_reverse_decline_interview: number,
    ocr_decline_interview: number
    faculty_highlight_featured_jobs_only: number,
    currency_symbol: string,
    display_interview_loc: number,
    facebook_app_id: number,
    consortia: boolean,
    disable_student_interview_cancel: number,
    ocr_cancel_start_time: string,
    ocr_cancel_end_time: string
}
