import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ListFilterSingleSelect, ListFilterMultipleSelect, ListFilterMultipleSelectField, ListFilterSingleSelectOption, DynamicFormMobileFilterComponent } from "@symplicity/syng";
import { FormConfig } from '@symplicity/syng';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { ListDynamicFormFilterService, FilterField } from '@csm/shared';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'list-dynamic-form-filter',
    templateUrl: './list-dynamic-form-filter.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ListDynamicFormFilterComponent {
    isInit: boolean = false;
    backup: UntypedFormGroup;
    lastParam = '';
    dropdownFilters = [];
    _formConfig: FormConfig;

    @Input() submitOnClose = true;
    @Input() filters: FilterField[] = [];
    @Input() styleClass = '';
    @Input() formGroup: UntypedFormGroup;
    @Input() mobileClass:string;

    @Input()
    public moreFiltersControls;

    @Input()
    public moreFiltersValues: any;

    @Output() submitFilter: EventEmitter<any> = new EventEmitter();

    @Input()
    get formConfig(): FormConfig {
        return this._formConfig;
    }

    set formConfig(formConfig: FormConfig) {
        this._formConfig = formConfig;
        if (this.isInit) {
            this.updateForms();
        }
    }

    constructor(
        private translate: TranslateService,
        private listDynamicFormFilterService: ListDynamicFormFilterService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        if (this.formConfig) {
            this.updateForms();
            this.isInit = true;
        }

        if (!this.mobileClass) {
            this.mobileClass = 'display-sm-none'
        }
    }

    ngOnChanges() {
        let formValues = JSON.stringify(this.formGroup.value);
        let hasChanged = this.lastParam !== formValues;

        if (hasChanged) {
            this.lastParam = formValues;
        }
    }

    public openMoreFilters(): void {
        const modal = this.modalService.open(DynamicFormMobileFilterComponent, {
            windowClass: 'ngb-modal modal-scroll',
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modal.componentInstance.title = 'misc.Filters';
        modal.componentInstance.controls = this.moreFiltersControls;
        modal.componentInstance.formGroup = this.createFormGroup(this.moreFiltersControls, this.moreFiltersValues);
        modal.componentInstance.styleClass = 'more-filter-dropdown mobile-filter';

        from(modal.result).pipe(
            filter(result => !!result),
            take(1)
        ).subscribe(result => this.submitFilters(result));
    }

    private createFormGroup(controls: any[], filterData = {}): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});

        controls.map(control => {
            if (filterData[control.name]) {
                if (!control.multiple || Array.isArray(filterData[control.name])) {
                    control.value = filterData[control.name];
                } else {
                    control.value = new Array(filterData[control.name]);
                }
            } else {
                control.value = undefined;
            }

            formGroup.addControl(control.name, new UntypedFormControl(control.value, control.validators));
        });

        return formGroup;
    }


    updateForms() {
        this.dropdownFilters = this.getDropdownFilters();
        this.formGroupBackup();
    }

    formGroupBackup() {
        this.backup = _.cloneDeep(this.formGroup);
    }

    submitFilters(value = undefined): void {
        if (value) {
            this.formGroup.patchValue(value);
        }

        this.submitFilter.emit(this.formGroup.value);
    }

    getDropdownFilters() {
        return this.listDynamicFormFilterService.getDropdownFiltersData(this.formConfig, this.filters);
    }

    onDropdownShown(filter) {
        this.formGroupBackup(); 
        if (filter.onShow instanceof Function) {
            filter.onShow();
        }
    }

    getFilterType(filter): string {
        let type = 'dynamic-form';
        const useExternalFilterComponents = ['select', 'checkbox'];
        if (!filter.autoSave && useExternalFilterComponents.includes(filter.control.type)) {
            type = filter.control.type;
        }
        return type;
    }

    getSingleSelectFilter(control): ListFilterSingleSelect {
        let filter = [];
        if (!(control.options instanceof Array) && !control.options._isScalar) {
            filter = control.options.source._value.map(opt => {
                return new ListFilterSingleSelectOption({
                    label: opt.value,
                    value: opt.id
                });
            });
        } else {
            filter = control.options.map(opt => {
                return new ListFilterSingleSelectOption({
                    label: opt.display,
                    value: opt.id
                });
            });
        }
        return new ListFilterSingleSelect({
            id: control.id,
            title: this.translate.instant(control.label),
            options: filter,
            value: control.value || undefined
        });
    }

    getMultipleSelectFilter(control, searchable = true): ListFilterMultipleSelect {

        let fields;
        if (!(control.options instanceof Array) && !control.options._isScalar) {
            fields = control.options.source._value.map(opt => {
                return new ListFilterMultipleSelectField({
                    id: opt.id,
                    label: opt.value,
                    value: _.isArray(control.value) ? control.value.includes(opt.id) : control.value === opt.id
                });
            });
        } else {
            fields = control.options.map(opt => {
                return new ListFilterMultipleSelectField({
                    id: opt.id,
                    label: opt.value,
                    value: _.isArray(control.value) ? control.value.includes(opt.id) : control.value === opt.id
                });
            });
        }
        return new ListFilterMultipleSelect({
            id: control.id,
            title: this.translate.instant(control.label),
            fields: fields,
            searchField: searchable,
            activeFilters: control.showActiveFilters ? control.value?.length : undefined
        });
    }

    submitSingleSelectFilter(filterValue) {
        this.submitDropdownFilterChanges(filterValue.id, filterValue.value);
    }

    submitMultipleSelectFilter(filterValue) {
        let value = filterValue.fields.map(f => f.value ? f.id : null).filter(f => f);
        this.submitDropdownFilterChanges(filterValue.id, value);
    }

    submitDropdownFilterChanges(id, value) {
        this.formGroup.get(id).setValue(value);
        this.submitFilters(this.formGroup.value);
    }

    closeFilter(values) {
        if (!this.submitOnClose) {
            return;
        }
        const resetValues = _.cloneDeep(values);
        for (let key in this.formGroup.value) {
            if (key in resetValues) {
                resetValues[key] = this.backup.controls[key].value;
            } else {
                resetValues[key] = this.formGroup.value[key];
            }
        }
        this.submitFilters(resetValues);
    }
}
