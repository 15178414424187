import { Injectable } from '@angular/core';
import { TranslationButton, TranslationMenu, TranslationMenuItem, ValkyrieCommonService } from '@symplicity/syng';

export interface TextEditorConfig {
    label?: string;
    url: string;
    lttype: string;
    domain: string;
    return_to_referrer?: string;
}

declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class TextEditorService {
    constructor(private commonService: ValkyrieCommonService) {}

    public setConfig(configs: TextEditorConfig[]): void {
        if (window.TRANSLATION_MODE === 1 && configs.length) {
            const menuLabel = (window.LANG_TRANSLATION_MULTILINGUAL_MODE === 1) ? 'misc.Translate Labels' : 'misc.Edit Labels';

            if (configs.length === 1) {
                this.commonService.setTranslationButton(new TranslationButton(menuLabel, this.getTranslationLink(configs[0])));
            } else {
                this.commonService.setTranslationMenu(new TranslationMenu(menuLabel, configs.map(config =>
                    new TranslationMenuItem(config.label || config.domain, this.getTranslationLink(config))
                )));
            }

            this.commonService.setShowTranslationMenu(true);
        }
    }

    private getTranslationLink(config: TextEditorConfig): string {
        let link = `${config.url}${window.ngData?.tabSessionLink || ''}?___lt=1&lttype=${config.lttype}&domain=${config.domain}`;

        if (config.return_to_referrer) {
            link += `&return_to_referrer=${config.return_to_referrer}`;
        }

        return link;
    }
}
