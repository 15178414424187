import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class SessionStorageService {

    getParsed(key: string) {
        try {
            return JSON.parse(window.sessionStorage.getItem(key));
        } catch {
            console.error("SessionStorageService Error: Json parse error.");
        }
    }

    setJson(key: string, value: any) {
        try {
            window.sessionStorage.setItem(key, JSON.stringify(value));
        } catch {
            console.error("SessionStorageService Error: Json stringify error.");
        }
    }

    getItem(key: string) {
        return window.sessionStorage.getItem(key);
    }

    setItem(key: string, value: any) {
        window.sessionStorage.setItem(key, value);
    }

    clear() {
        window.sessionStorage.clear();
    }

    remveItem(key: string) {
        window.sessionStorage.removeItem(key);
    }

    keys() {
        return Object.keys(window.sessionStorage);
    }

    get length() {
        return window.sessionStorage.length;
    }
}
