<div class="header">
    <button class="modal-close" type="button" [attr.aria-label]="'misc.Close' | translate" (click)="cancelFilterChanges()">
        <div class="icn-close"></div>
    </button>
    <h3>{{ title | translate }}</h3>
    <button class="btn-link btn_text-link body-small" type="button" (click)="clearFilters()">{{ 'misc.Clear' | translate }}</button>
</div>

<div class="more-filter-body">
    <ng-container *ngIf="controls && controls.length">
        <ng-template [ngTemplateOutlet]="filterList" [ngTemplateOutletContext]="{ controls: controls }"></ng-template>
    </ng-container>

    <ng-container *ngFor="let section of groupFilters">
        <div class="section group-fields list-border" [ngClass]="section.styleClass">
            <h3>{{section.label}}</h3>
            <div class="fields-wrapper">
                <ng-template [ngTemplateOutlet]="filterList" [ngTemplateOutletContext]="{ controls: section.control }"></ng-template>
            </div>
        </div>
    </ng-container>
</div>

<div class="footer">
    <!-- Bootstrap classes used to fix style clash. -->
    <button class="btn btn-default" type="button" (click)="clearFilters()" [attr.aria-label]="'events students.Clear More Filters' | translate">{{ 'misc.Clear' | translate }}</button>
    <button class="btn btn-default ml-2" type="button" (click)="cancelFilterChanges()" [attr.aria-label]="'events students.Cancel More Filters' | translate">{{ 'misc.Cancel' | translate }}</button>
    <button class="btn btn_primary ml-2" type="button" (click)="applyFilters()" [attr.aria-label]="'events students.Apply More Filters' | translate">{{ 'misc.Apply' | translate }}</button>
</div>

<ng-template #filterList let-controls="controls">
    <ng-container *ngFor="let control of controls; odd as isOdd">
        <div class="filter-grid {{control.styleClass}}" [ngClass]="getStyleClass(control, isOdd)">
            <div
                dynamicField
                class="field"
                [group]="formGroup"
                [config]="control"
                [value]="control.value"
            ></div>
        </div>
    </ng-container>
</ng-template>
