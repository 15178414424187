import { Injectable } from '@angular/core';
import { ValkyrieBackendService } from '@symplicity/syng';
import { Observable } from 'rxjs';

@Injectable()
export class UnSubscribeService {
    constructor(private backendService: ValkyrieBackendService) {}

    unsubscribe(resource, payload): Observable<any> {
        return this.backendService.save('unsubscribe/' + resource, payload, 'v3');
    }
}
