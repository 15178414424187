import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from 'rxjs';

declare let gtag: Function;

@Component({
    selector: 'home-tiles',
    templateUrl: './home-tiles.component.html',
})

export class HomeTilesComponent implements OnInit {

    @Input() tiles: Observable<any>;

    tilesArr = [];
    tilesLoaded: boolean = false;
    limit = 5;
    constructor (
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.tiles.subscribe(resp => {
            this.tilesArr = this.setupTiles(resp.models);
            this.tilesLoaded = true;
        });
    }

    setupTiles(tiles) {
        let tilesList = [];
        tiles.forEach(tile => {
            if (tilesList.length < this.limit) {
                if (tile.link && tile.module_id !== 'recruit') {
                    tile.link = '/' + tile.link;
                }
                tilesList.push(tile);
            }
        })
        return tilesList;
    }

    totalTiles() {
        let totalTiles = 0;
        if (this.tilesLoaded) {
            totalTiles = this.tilesArr!.length;
        } 
        return totalTiles;
    }

    sendAnalytics(tile) {
        let params = {
            'event_action': '',
            'event_category': ''
        };

        if (tile === 'public_profile' || tile === 'resume') {
            params['event_category'] = 'Students';
        } else {
            params['event_category'] = 'Employers';
        }

        const tileActionMap = {
            'public_profile': 'Public Profile',
            'resume': 'Add Resume',
            'recruit': 'Recruit',
            'explearning': 'Experiential Learning',
            'non-ocr': 'Applications',
            'career_fair': 'Career Fair',
            'job': 'Post a Job',
            'profile': 'Update Profile',
            'rezbook': 'Resume Books',
            'ocr': 'On Campus Interviews',
            'info_sess': 'Information Session'
        };

        if (!tileActionMap[tile]) {
            console.warn(`Invalid tile: ${tile}. Event tracking skipped.`);
            return;
        }

        params['event_action'] = `Homepage ${tileActionMap[tile]} Tile Clicked`;
        gtag('event', 'button_click', params);
    }
}