<div class="location_list_widget">
    <ng-container *ngIf="isVirtualFair; then VirtualFairWhenAndWhere; else RegularFairWhenAndWhere;">
    </ng-container>
</div>

<ng-template #VirtualFairWhenAndWhere>
    <div class="location_list list-item-row">
        <div class="list-item-content list-border flexbox space-right-lg">
            <div class="space-right-lg">
                <div class="avatar avatar-md avatar-square avatar-calendar monogram">
                    <div class="calendar-wrapper">
                        <div class="month">
                            {{ virtualFairDayItem.month }}
                        </div>
                        <div class="day">
                            {{ virtualFairDayItem.day }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="body-small">
                <h3 class="space-top-xs">
                    {{ virtualFairDayItem.label }}
                </h3>
                <div class="list-item-subtitle space-top-xs">
                    {{ virtualFairDayItem.blurb | translate }}
                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #RegularFairWhenAndWhere>
    <div class="location_list list-item-row">
        <div *ngFor="let day of regularFairDayItems" class="list-item-content list-border flexbox space-right-lg">
            <div class="space-right-lg">
                <div class="avatar avatar-md avatar-square avatar-calendar monogram">
                    <div class="calendar-wrapper">
                        <div class="month">
                            {{ day.month }}
                        </div>
                        <div class="day">
                            {{ day.day }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="body-small">
                <h3 class="space-top-xs">
                    {{ day.label }}
                </h3>
                <div class="list-item-subtitle space-top-xs space-bottom-xs">
                    {{ day.time }}
                </div>
                <div class="text-primary">
                    <ng-container *ngIf="day.addressLabel">
                        {{ day.addressLabel }} <br>
                    </ng-container>

                    <a target="_blank" [href]="day.mapUrl">
                        {{ day.address }}
                    </a>
                </div>
                <div class="space-top-xs xl-text">
                    <span *ngIf="day.boothmapCount" class="space-right-sm">
                        <button
                            class="btn btn_icn btn_alt-default"
                            container="body"
                            [tooltip]="boothmapString | translate"
                            (click)="onOpenBoothmapModal(day)"
                        >
                            <i class="icn-map"></i>
                            <span class="sr-only">{{ boothmapString | translate }}</span>
                        </button>
                    </span>

                    <span *ngIf="day.parkingInformation" class="space-right-sm">
                        <button
                            class="btn btn_icn btn_alt-default"
                            container="body"
                            [tooltip]="parkingString | translate"
                            (click)="onOpenParkingInfoModal(day)"
                        >
                            <i class="icn-parking"></i>
                            <span class="sr-only">{{ parkingString | translate }}</span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="location_map hide display-desktop-block">
        <img [src]="mapSrc" alt="" crossorigin>
    </div>
</ng-template>
