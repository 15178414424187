<ng-container *ngIf="announcementsListItems; else loadingMoreSkeleton;">
    <ng-container *ngIf="announcementsListItems.length">
        <h2 class="space-top-xl">{{ translationDomain + '.Announcements' | translate }}</h2>
        <list-page #listPage
        [styleClass]="'field-widget-tinymce announcements-home-list'"
        [items]="announcementsListItems"
        [rowAnchor]="rowAnchor"
        [pagination]="false"
        [header]="false"
        [displaySort]="false"
        [autoScrollTop]="false"
        ></list-page>

        <ng-container *ngIf="loadingMore" [ngTemplateOutlet]="loadingMoreSkeleton"></ng-container>

        <div *ngIf="showLoadMore && !loadingMore" class="padding-lg border-top bg-white">
            <button class="btn btn_link text-info" (click)="loadMore()">{{ translationDomain + ".Show more Announcements" | translate }}</button>
        </div>
    </ng-container>
</ng-container>


<!---------------------------- Templates ---------------------------->
<ng-template #loadingMoreSkeleton>
    <div style="border-top: 1px solid #ececec">
        <div class="list-item list_rows list-item-logo bg-white" *ngFor="let i of missingQuantity">
            <div class="skeleton-fill avatar thumbnailbox list-item-logo"><span class="skeleton-animation"></span></div>
            <div class="list-item-body">
                <div class="skeleton-bar col-8"><span class="skeleton-animation"></span></div>
                <div class="skeleton-bar col-6"><span class="skeleton-animation"></span></div>
                <div class="skeleton-bar col-4"><span class="skeleton-animation"></span></div>
                <div class="skeleton-bar col-2"><span class="skeleton-animation"></span></div>
                <div class="skeleton-bar col-1"><span class="skeleton-animation"></span></div>
            </div>
        </div>
    </div>
</ng-template>
