import { Component, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms'

@Component({
    selector: 'group-filters',
    templateUrl: './group-filters.component.html'
})

export class GroupFiltersComponent {
    _controls: any = [];
    @Input() groupFilters = [];
    @Input() title: string = '';
    @Input() dropdown;
    @Input() formGroup: UntypedFormGroup;
    @Output() onClearFilters: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
    @Output() onApplyFilters: EventEmitter<any> = new EventEmitter();

    @Input()
    get controls() {
        return this._controls;
    }
    set controls(value) {
        this._controls = value || [];
    }

    constructor(
        private elementRef: ElementRef
    ) {}

    cancelFilterChanges(): void {
        this.onCancel.emit(this.getResetValues());
        this.dropdown.hide();
    }

    clearFilters(): void {
        this.formGroup.reset(this.getResetValues());
        this.elementRef.nativeElement.querySelectorAll('input[type=checkbox]:checked').forEach(e => { e.checked = false });
    }

    getResetValues() {
        const resetValuesObj = {};
        this.controls.forEach(control => {
            if (control.type == 'rangeDatePicker') {
                resetValuesObj[control.name] = ['', ''];
                return;
            }
            if (control.type == 'numberComparison') {
                resetValuesObj[control.name] = { "operator": "", "number": "" };
                return;
            }
            if (control.defaultValue) {
                resetValuesObj[control.name] = control.defaultValue;
                return;
            }
            if (control.multiple) {
                resetValuesObj[control.name] = [];
            } else {
                resetValuesObj[control.name] = null;
            }
        });
        this.groupFilters.forEach(group => {
            group.control.forEach(control => {
                if (control.multiple) {
                    resetValuesObj[control.name] = [];
                } else {
                    resetValuesObj[control.name] = null;
                }
            });
        })
        return resetValuesObj;
    }

    applyFilters(): void {
        this.onApplyFilters.emit(this.formGroup.value);
        this.dropdown.hide();
    }

    getStyleClass(control, isOdd) {
        let style = isOdd ? 'right' : '';
        if (control.type === 'checkbox' && control.options.length > 5) {
            style += ' overflow-widget';
        }
        return style;
    }

    getGroupControl(item) {
        return this.controls.filter(ctrl => item.field.includes(ctrl.id));
    }
}
