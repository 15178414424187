import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ValkyrieCommonService } from '@symplicity/syng';
import { STUDENT_WINDOW } from '@app/students.types';
import { ProfileService } from '../profiles/profile.service';
declare var window: STUDENT_WINDOW;

@Component({
    selector: 'error-component',
    templateUrl: './404.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ErrorComponent implements OnInit {

    public brandingImage: string;
    public brandingLink: string;
    public htmlElement: any;
    public bodyElement: any;
    public loginWrapper: any;
    public errorMessage: string;

    constructor(
        private commonService: ValkyrieCommonService,
        private renderer: Renderer2,
        private modalService: NgbModal,
        private translateService: TranslateService,
        private profileService: ProfileService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.setupErrorTemplate();
        this.commonService.setFloatingLayout(false);
        this.commonService.setEnableBreadcrumbs(false);
        this.commonService.setEnableHints(false);
        this.brandingImage = window.BRANDING_IMAGE || '/si_ei/images/csm-logo-default.png';
        this.brandingLink = window.BRANDING_LINK;
        this.errorMessage = this.route.snapshot.paramMap.get('message') || 'misc.No one likes to be lost in space so let\'s get you back to orbit';
    }

    setupErrorTemplate() {
        this.htmlElement = this.renderer.selectRootElement('html', true);
        this.loginWrapper = this.renderer.selectRootElement('.login-wrapper', true);
        this.bodyElement = this.renderer.selectRootElement('body', true);
        this.renderer.addClass(this.bodyElement, 'flexbox');
        this.renderer.addClass(this.bodyElement, 'justify-content-center');
        this.renderer.addClass(this.bodyElement, 'align-items-center');
        this.renderer.addClass(this.htmlElement, 'login-page');
    }

    goBack() {
        window.history.go(-1);
    }

}