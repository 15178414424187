import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatPipe, IsAfterPipe, IsBeforePipe } from 'ngx-moment';
import { TranslationsModule } from '@symplicity/syng';
import { STUDENT_WINDOW } from './students.types'

import { AppComponent } from './app.component';
import { APP_ROUTES }   from './routes';
import { UnSubscribeService, EventsService } from './services';

declare var window: STUDENT_WINDOW;

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(APP_ROUTES, {
    scrollPositionRestoration: 'top'
}),
        TranslationsModule.forRoot(),
        CommonModule,
        NgbModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: Window,
            useValue: window
        },
        DatePipe,
        UnSubscribeService,
        EventsService,
        TranslateService,
        DateFormatPipe,
        IsAfterPipe,
        IsBeforePipe
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}

declare global {
    interface Window {
        LANG: string;
        USER_TZ: string;
        TRANSLATION_MODE: number;
        LANG_TRANSLATION_MULTILINGUAL_MODE: number;
        ACCESIBILITY_MODE: number;
        BRANDING_LINK: string;
        BRANDING_IMAGE: string;
        DEFAULT_BRANDING_IMAGE: string,
        CONSORTIA: boolean;
        CURRENT_DATE: string;
        CURRENT_SECTION: string;
        LANGPACK: string;
        LOCALE: string;
        SYMPDEBUG_LOGIN: string;
        TRANSLATION_LANGUAGES: any;
        SUB_SYSTEM: string;
        USE_LANGUAGE_PACK: boolean;
        USE_OCI: boolean;
        MAX_DOCUMENT_FILESIZE_UPLOAD: number;
        STUDENT_ID: string;
        SYSTEM_NAME: string;
        DailyIframe: Function & { createFrame: Function };
        DATETIME_FORMAT: any;
        ngData: {
            isLawCsm: boolean;
            countryCode: string;
            system_title: string;
            account_disabled: number;
            branding_colors: any;
            language_pack: string;
            manager_loginas: string
            section_title: string;
            show_symp_branding: string;
            tabSessionLink: string
            textfooter: string
            unifiedEvents: boolean;
            unifiedEventsCfList: boolean;
            unifiedEventsIsList: boolean;
            unifiedEventsWsList: boolean;
            disable_mobile_app_promotions: '0' | '1' | null;
            studentOnboardingEligible: boolean;
            useGeoLocationSearch: boolean;
            pdfUploadCheckInterval: number;
            showReportHire: boolean;
            studentOnboardingSettings?: {
                SHOW_GDPR: any,
                SHOW_PROMOTE_ME: any,
                SHOW_ONBOARDING: any,
                ONBOARDING_EXISTS: any,
                LAW_SCHOOL: any,
                TURN_ON_PELIAS_CAREER_INTEREST: any,
                SHOW_EMPLOYER_SUPPORTS: any,
                ENABLE_PSX: any,
                SHOW_CAREER_INTEREST: any,
                CURRENT_CAREER_INTEREST_ID: any,
                STUDENT_PROFILE: any,
                CURRENT_EMAIL_PREFERENCES: any,
                ACTIVATE_EMPLOYER_INVITES: any,
                PELIAS_VALIDATION_LAYERS: any,
            };
        }
    }
}
