import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";

declare var window: Window;

@Directive({ selector: '[intersectionObserver]' })
export class IntersectionObserverDirective implements OnInit, OnDestroy {

    @Input('intersectionObserver') intersectionCallback : (x: IntersectionObserverEntry[] ) => any;
    @Input() intersectionConfig?: IntersectionObserverInit;

    intersectionSubscription: Subscription;

    constructor(
        private el: ElementRef
    ) {}

    ngOnInit() {
        if (!(typeof this.intersectionCallback == 'function') || !('IntersectionObserver' in window)) {
            return;
        }

        this.intersectionSubscription = fromIntersectionObserver(this.el.nativeElement, this.intersectionConfig).subscribe(this.intersectionCallback);
    }

    ngOnDestroy() {
        this.intersectionSubscription.unsubscribe();
    }
}


/*
    Observable wrapper
*/
export function fromIntersectionObserver(elements: HTMLElement | HTMLElement[] | NodeList, options?: IntersectionObserverInit) {
    if (!options) {
        options = {};
    }
    options.root = options.root || null;
    options.rootMargin = options.rootMargin || '-64px 0px 0px';
    options.threshold = options.threshold || .1;

    if (elements instanceof HTMLElement) {
        elements = [elements];
    }

    let observable = new Observable<IntersectionObserverEntry[]>(subscriber => {
        let observer = new IntersectionObserver((entries, observer) => {
            subscriber.next(entries);
        }, options);

        (elements as HTMLElement[]).forEach((el) => observer.observe(el))

        return function unsubscribe() {
            (elements as HTMLElement[]).forEach((el) => observer.unobserve(el))
        }
    })
    return observable;
}
