import { Component, Input, Output, ViewEncapsulation, EventEmitter, Inject } from '@angular/core';
import { DynamicFormMobileFilterComponent } from "@symplicity/syng";
import { FormConfig } from '@symplicity/syng';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterField } from '@csm/shared';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'list-dynamic-form-filter-fields',
    templateUrl: './list-dynamic-form-filter-fields.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ListDynamicFormFilterFieldsComponent {
    formFilters = [];
    fieldControls = [];
    @Input() filters: FilterField[];
    @Input() formConfig: FormConfig;
    @Input() formGroup: UntypedFormGroup;
    @Input() styleClass: string;
    @Input() mobileFilters = [];
    @Input() onlyMobileFilters = false;
    @Input() mobileGroupFilters = [];
    @Input() mobileClass: string;
    @Output() submitFilter: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private modalService: NgbModal,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        this.fieldControls = this.filters.map(f => this.getFormConfigControl(f.field));
        if (!this.mobileClass) {
            this.mobileClass = 'display-sm-inline-block';
        }
    }

    filtersKeyEvent(fieldName, type, event) {
        if (type === 'keypress' && event.key === 'Enter') {
            this.submitFormFilters(fieldName);
        }
    }

    getFormConfigControl(id) {
        return this.formConfig.controls.find(f => f.id === id);
    }

    getControlsId(): string[] {
        return this.fieldControls.map(ctrl => ctrl.id);
    }

    submitFormFilters(fieldName: string|string[] = this.getControlsId()) {
        let formValues = _.pick(this.formGroup.value, fieldName);
        this.submitFilter.emit(formValues);
    }

    submitModalFilters(value = this.formGroup.value) {
        let modalValues = _.omit(value, this.fieldControls);
        this.submitFilter.emit(modalValues);
    }

    updateControlElementId(ctrl) {
        if (ctrl) {
            ctrl.elementId = (ctrl.elementId ? ctrl.elementId : 'form-filter-' + ctrl.id) + '-mobile';
        }
    }

    openMobileFilterWindow(): void {
        const modal = this.modalService.open(DynamicFormMobileFilterComponent, {
            windowClass: 'ngb-modal',
            size: 'lg',
            backdrop: 'static'
        });

        this.mobileFilters.forEach(ctrl => {
            this.updateControlElementId(ctrl);
        });
        this.mobileGroupFilters.forEach(filter => {
            if (_.isArray(filter.control)) {
                filter.control.forEach(ctrl => {
                    this.updateControlElementId(ctrl.control);
                });
            } else {
                this.updateControlElementId(filter.control);
            }
        })

        modal.componentInstance.title = 'misc.Filters';
        modal.componentInstance.controls = this.mobileFilters;
        modal.componentInstance.groupFilters = this.mobileGroupFilters;
        modal.componentInstance.formGroup = this.formGroup;
        modal.componentInstance.styleClass = 'more-filter-dropdown mobile-filter';

        this.document.body.classList.add('ngb-modal-stack-open');

        modal.result.then(values => {
            this.document.body.classList.remove('ngb-modal-stack-open');
            if (values) {
                this.submitModalFilters(values);
            }
        });
    }
}
