<div class="modal-header" [ngClass]="{ 'white-header': whiteHeader }">
    <h3 class="modal-title" tabindex="-1">{{alertHeaderTitle | translate}}</h3>
    <button class="modal-close" [attr.aria-label]="'misc.Close' | translate" (click)="onCancel()"></button>
</div>
<div class="modal-body">
    <ng-container *ngIf="!alertTitle">
        <span>{{alertTitle | translate}}</span>
    </ng-container>
    <ng-container *ngIf="!useTemplateRef">
        <ng-container *ngIf="!isAlertMessageTranslated">
            <span>{{alertMessage | translate}}</span>
        </ng-container>
        <ng-container *ngIf="isAlertMessageTranslated">
            <div [innerHTML]="htmlContent"></div>
        </ng-container>
    </ng-container>
    <ng-container #templateRef></ng-container>
</div>
<div class="modal-footer" [ngClass]="{ 'white-footer': whiteFooter }" *ngIf="hasFooter">
    <ng-container *ngIf="!alertCancelButtonName && alertButtonName">
        <button class="btn" [ngClass]="alertButtonClass ? alertButtonClass : 'btn_primary'" (click)="onConfirm()">{{alertButtonName | translate}}</button>
    </ng-container>
    <ng-container *ngIf="alertCancelButtonName">
        <button class="btn btn_default" (click)="onCancel()">{{ alertCancelButtonName | translate}}</button>
        <button class="btn" [ngClass]="alertButtonClass ? alertButtonClass : 'btn_primary'" (click)="onConfirm()">{{ alertButtonName | translate}}</button>
    </ng-container>
</div>