import { Routes } from '@angular/router';
import { ErrorComponent } from "./common/404.component";

export const APP_ROUTES: Routes = [
    {
        path: 'public',
        loadChildren: () => import('./unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
    },
    {
        path: 'profiles/:id',
        loadChildren: () => import('./profiles/profile.module').then(m => m.ProfileModule)
    },
    {
        path: '404',
        component: ErrorComponent,
        data : {
            navTarget: 'Error'
        }
    }
];
