export class RegularFairDay {
    id: string;
    start: string;
    end: string;
    isSingleDay: boolean;
    date: string;
    label: string;
    addressLabel: string;
    mapUrl: string;
    address: string;
    boothmapCount: number;
    parkingInformation: string;

    constructor(config: RegularFairDay) {
        Object.assign(this, config);
    }
}

export class RegularFairDayItem {
    id: string;
    month: string;
    day: string;
    date: string;
    label: string;
    time: string;
    addressLabel: string;
    mapUrl: string;
    address: string;
    boothmapCount: number;
    parkingInformation: string;

    constructor(config: RegularFairDayItem) {
        Object.assign(this, config);
    }
}

export class VirtualFairDay {
    start: string;
    end: string;
    blurb: string;

    constructor(config: VirtualFairDay) {
        Object.assign(this, config);
    }
}

export class VirtualFairDayItem {
    month: string;
    day: string;
    label: string;
    blurb: string;

    constructor(config: VirtualFairDayItem) {
        Object.assign(this, config);
    }
}
