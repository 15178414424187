import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from "@angular/core";
import { asyncScheduler, fromEvent } from "rxjs";
import { takeUntil, throttleTime } from "rxjs/operators";
import { DestroyEmitter } from "@symplicity/syng";

declare var window: Window;

@Component({
    selector: 'back-to-top',
    template: `
        <button *ngIf="show" (click)="backToTop()" class="back-to-top btn btn_icn btn_primary btn_circle">
            <span class="icn-back_to_top icon-md">
                <span class="sr-only">{{'misc.Back to Top' | translate}}</span>
            </span>
        </button>
    `,
    providers: [ DestroyEmitter ]
})
export class BackToTopComponent implements OnInit {

    show = false;

    @Input() config: BackToTopConfig = {};

    constructor(
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private onDestroy$: DestroyEmitter
    ) {}

    ngOnInit(): void {
        const defaultConfig: BackToTopConfig = { elementQuery: '[name="content"]', windowMultiplyTrigger: 1 };
        this.config = {...defaultConfig, ...this.config};
        this.zone.runOutsideAngular(() => {
            const windowHeight = window.innerHeight;
            fromEvent(window, 'scroll').pipe(
                takeUntil(this.onDestroy$),
                throttleTime(150, asyncScheduler, { trailing: true })
            ).subscribe( e => {
                const scrollY = window.scrollY;
                if (scrollY >= this.config.windowMultiplyTrigger * windowHeight) {
                    if (!this.show) {
                        this.show = true;
                        this.cd.detectChanges();
                    }
                } else if (this.show) {
                    this.show = false;
                    this.cd.detectChanges();
                }
            })
        })
    }

    backToTop(): void {
        const el = document.querySelector(this.config.elementQuery) as HTMLElement;
        if (el) {
            el.tabIndex = -1;
            el.focus();
        }
        window.scroll(0,0);
    }
}

interface BackToTopConfig {
    elementQuery?: string;
    windowMultiplyTrigger?: number
}
