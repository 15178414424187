import { DOCUMENT } from '@angular/common';
import { Input, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
import { EventDateService } from "@csm/shared";
import { ListItemElement, RowAnchorModel, StandardListPageModel, Thumbnail, ValkyrieBackendService } from '@symplicity/syng'

declare var window: Window;

@Component({
    selector: 'announcements-list',
    templateUrl: 'announcements-list.component.html'
})
export class AnnouncementsList implements OnInit {

    announcementsListItems?: StandardListPageModel[];
    rowAnchor: RowAnchorModel = new RowAnchorModel();
    page = 1;
    showLoadMore: boolean;
    loadingMore = false;
    missingQuantity: any[];

    @Input() translationDomain: string;
    @Output() onOpenModal = new EventEmitter<any>();
    @Output() emptyModels = new EventEmitter<void>();
    @ViewChild('listPage', {static: false, read: ElementRef}) listPageEl: ElementRef<HTMLElement>;

    constructor(
        private backendService: ValkyrieBackendService,
        private dateService: EventDateService,
        private translate: TranslateService,
        private ngChangeDetection: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: Document,
        ) {}

    ngOnInit() {
        this.missingQuantity = [1, 2, 3, 4, 5]; // Start with 5 rows the skeleton loader
        this.getAnnouncements();
        this.rowAnchor = {
            onClick: (value) => {
                const target = value.event.target as HTMLElement;
                const anchor = target.closest("a");
                if (anchor && anchor.href) {
                    if (anchor.host === window.location.host) {
                        window.location.href = anchor.href;
                    } else {
                        window.open(anchor.href);
                    }
                    return false;
                } else {
                    this.onOpenModal.emit(value.item.id);
                }
            },
            enable: true
        };
    }

    getAnnouncements() {
        this.backendService.getAll('announcements', 'v2', { perPage: 5, page: this.page })
            .subscribe(res => {
                if (!res.models.length) {
                    this.announcementsListItems = [];
                    this.emptyModels.emit();
                    return;
                }
                this.showLoadMore = res.total > (res.page * res.perPage);
                if (this.showLoadMore) {
                    this.missingQuantity = [...new Array(Math.min(5, res.total - (res.page * res.perPage)))];
                }
                this.getAnnouncementsItems(res.models)});
    }

    getThumbnail(item) {
        return new Thumbnail({
            type: item.background_image ? 'image' : 'icon',
            src: item.background_image ? item.background_image : 'icn-announcement',
            styleClass: 'thumbnail-mobile-avatar thumbnail-icon icn-autocolor',
            autoColor: item.announcement_id,
            imagePosition: item.background_image_alignment ? item.background_image_alignment.replace('_', ' ') : 'center center'
        });
    }

    getAnnouncementsItems(models) {
        let items = [];
        models.forEach(model => {
            let dateLabel = this.dateService.convertDate(model.utc_date, this.dateService.getConfigDateFormat());
            let item: StandardListPageModel = {
                rowAnchor: undefined,
                id: model.announcement_id,
                texts: [
                    new ListItemElement({
                        label: model.title,
                        defaultStyleType: 'title',
                        styleClass: 'inline'
                    }),
                    new ListItemElement({
                        label:  this.translate.instant("misc.Published on date", {date: dateLabel}),
                        defaultStyleType: 'subtitle',
                        styleClass: 'text-gray'
                    }),
                    new ListItemElement({
                        type: 'textSafe',
                        label: this.stripInlineStyles(model.body),
                        defaultStyleType: 'text',
                        styleClass: 'space-top-xs'
                    })
                ],
                styleClass: 'list-item-responsive-actions no-text-underline bg-white',
                thumbnails: this.getThumbnail(model),
                ariaLabel: model.title || '',
            }
            items.push(item);
        });
        this.announcementsListItems = this.announcementsListItems ? [...this.announcementsListItems, ...items] : items;
        this.loadingMore = false;
        this.ngChangeDetection.detectChanges();
        if (this.page > 1) { // Restore focus to first new item in the list after load more.
            const indexFocus = 5 * (this.page - 1);
            const items = this.listPageEl.nativeElement.querySelectorAll('.list-item.list_rows');
            (items[indexFocus] as HTMLElement).focus();
        }
    }

    stripInlineStyles(html: string) {
        const element = this._document.createElement('div');
        element.innerHTML = html;
        const spans = element.querySelectorAll('span');
        spans.forEach(span => {
            span.style.removeProperty("font");
            span.style.removeProperty("font-family");
            span.style.removeProperty("font-size");
            span.style.removeProperty("font-weight");
            span.style.removeProperty("font-style");
            span.style.removeProperty("text-align");
            span.style.removeProperty("line-height");
            span.style.removeProperty("color");
            span.style.removeProperty("background");
            span.style.removeProperty("background-color");
        })
        return element.innerHTML;
    }

    loadMore() {
        this.loadingMore = true;
        this.page += 1;
        this.getAnnouncements();
    }
}
