<div class="filter-list flexbox flex-wrap" [ngClass]="styleClass">
    <ng-container *ngIf="filters?.length && _formConfig">
        <div class="display-mobile-none" [ngClass]="mobileClass">
            <div class="flexbox flex-wrap">
                <ng-container *ngFor="let filter of dropdownFilters">

                    <ng-container *ngIf="filter.isGroup">
                        <ng-template [ngTemplateOutlet]="groupDropdown" [ngTemplateOutletContext]="{ filter: filter }"></ng-template>
                    </ng-container>

                    <ng-container *ngIf="!filter.isGroup">
                        <ng-container [ngSwitch]="getFilterType(filter)">
                            <div *ngSwitchCase="'select'" list-simple-filter-single-select [filterData]="getSingleSelectFilter(filter.control)" (changes)="submitSingleSelectFilter($event)"></div>
                            <div *ngSwitchCase="'checkbox'" list-simple-filter-multiple-select [filterData]="getMultipleSelectFilter(filter.control, filter.options.searchField)" (changes)="submitMultipleSelectFilter($event)"></div>
                            <div
                                *ngSwitchDefault
                                dropdown-filter
                                [styleClass]="filter.options.styleClass"
                                [hideChipList]="filter.options.hideChipList"
                                [alwaysShowDropdownList]="filter.options.alwaysShowDropdownList"
                                [autoSave]="filter.autoSave"
                                [alwaysShowActionButtons]="filter.options.alwaysShowActionButtons"
                                [formGroup]="formGroup"
                                [control]="filter.control"
                                (onCancel)="closeFilter($event)"
                                (onApplyFilters)="submitFilters($event)"
                            >
                            </div>
                        </ng-container>
                    </ng-container>

                </ng-container>

                <div *ngIf="moreFiltersControls?.length" class="btn-group block more-filters-dropdown-group dropdown">
                    <button
                        class="btn btn_minimal filter-dropdown-btn more-filter-dropdown-btn no-caret"
                        type="button"
                        (click)="openMoreFilters()"
                    >
                        <span class="filter-text">{{ 'misc.More Filters' | translate }}</span>
                        <i class="icn-chevron_down"></i>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-content select="[aditionalFilterBtn]"></ng-content>
</div>

<ng-template #groupDropdown let-filter="filter">
    <div class="btn-group block more-filters-dropdown-group dropdown" dropdown #moreFilter="bs-dropdown" [insideClick]="true" [autoClose]="'always'" (onHidden)="submitOnClose && submitFilters($event)" (onShown)="onDropdownShown(filter);">
        <button id="cfEmployersAdvFiltersBtn" class="btn btn_minimal filter-dropdown-btn more-filter-dropdown-btn no-caret" type="button" aria-controls="cfEmployersAdvFilters" dropdownToggle>
            <span class="filter-text">
                {{ filter.label | translate }}
            </span>
            <i class="icn-chevron_down"></i>
        </button>
        <div class="dropdown-menu more-filter-dropdown dropdown-menu-right" id="cfEmployersAdvFilters" role="form" aria-labelledby="cfEmployersAdvFiltersBtn" *dropdownMenu>
            <ng-container *ngIf="moreFilter.isOpen">
                <group-filters [controls]="filter.control" [groupFilters]="filter.group" [formGroup]="formGroup" [dropdown]="moreFilter" (onCancel)="closeFilter($event)" (onApplyFilters)="submitFilters($event)"></group-filters>
            </ng-container>
        </div>
    </div>
</ng-template>
