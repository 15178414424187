import { StandardListPageModel } from '@symplicity/syng';

export type BooleanString = '1' | '0';

export interface ColumnMetadata {
    display: string;
    field: string;
}

export interface PaginationParams {
    page?: number;
    perPage?: number;
}

export interface EventsParams extends PaginationParams {
    view: EventView;
}

export type EventsRegistrationParams = PaginationParams & any;

export type EventView = 'upcoming' | 'past';

export type EventStatus = 'upcoming' | 'ongoing' | 'passed' | 'unknown';

export interface EventsResponse {
    className: string;
    columns: ColumnMetadata[];
    models: EventModel[];
    page: number;
    perPage: number;
    total: number;
}

export interface EventsItems extends EventsResponse {
    items: StandardListPageModel[];
}

export interface EventDate {
    isOngoing: boolean;
    isToday: boolean;
    label: string;
}

export interface EventModel {
    accept_regs: number;
    allow_student_rsvp: number;
    attendee_type: EventAttendeeType;
    background_image: string;
    background_image_alignment: string;
    days: EventDay[];
    disable_days: number;
    event_id: string;
    invite_employers: number;
    invite_students: number;
    label: string;
    nickname: string;
    overview: string;
    status: EventStatus;
    type: EventType;
    virtual_end: string;
    virtual_start: string;
}

export interface EventAttendeeType {
    _id: string;
    _label: string;
}

export interface EventDay {
    dates: {
        dayEnd: string;
        dayStart: string;
    };
    label: string;
    order: number;
    day_type?: string;
}

export interface EventLocation {
    address: string;
    lat: string;
    lng: string;
    location_label: string;
}

export interface EventType {
    _id: string;
    _label: string;
}

// TODO: Complete typings
export interface EventByIdResponse {
    event_id: string;
    label: string;
    nickname: string;
    type: EventType;
    video_vcf: BooleanString;
    day: EventByIdDay[];
    attendee_type: EventAttendeeType;
    student_reg_options: any[];
    accept_regs: BooleanString;
    display_student_picture: BooleanString;
    special_attendee: any[];
    allow_multiple_day_reg: BooleanString;
    disable_invoice: BooleanString;
    confirmation_blurb: string;
    disable_days: BooleanString;
    disable_attendees: BooleanString;
    allow_student_rsvp: BooleanString;
    allow_topten: BooleanString;
    student_queue_max: BooleanString;
    fair_header: string;
    fair_footer: string;
    allow_duplicate_emails: BooleanString;
    default_section_label: string;
    custom_day_label: string;
    custom_attendee_label: string;
    custom_pricing_label: string;
    default_section_blurb: string;
    custom_day_blurb: string;
    custom_attendee_blurb: string;
    custom_pricing_blurb: string;
    virtual_start: string;
    virtual_end: string;
    overview: string;
    promotional_msg: string;
    hero_banner: EventHeroBanner;
    enable_boothmap: BooleanString;
    join_info: any;
    help_information: any;
    student_visible: BooleanString;
    show_student_rsvp: BooleanString;
    invite_employers: BooleanString;
    status: EventStatus;
    static_map_url?: string;
    _id: string;
    dates: string;
    enable_student_portfolio: BooleanString;
    hide_invoice_til_app: BooleanString;
    virtual_start_timestamp: string;
    displayVirtualStartDate: string;
    virtual_end_timestamp: string;
    displayVirtualEndDate: string;
    stats: EventStats;
    banner: string;
    restricted_message: string;
    profile_fields: any[];
    list_fields: any[];
    disable_autofill: BooleanString;
    sympkey_blurb: string;
    vf_chat_refresh_rate: boolean;
    repForm: string;
}

export interface EventStats {
    students: number;
    employers: number;
}

export interface EventHeroBanner {
    hero_banner_id: string;
    headline: any;
    subheading: any;
    background_image: string;
    background_image_thumb: string;
    background_image_alignment: {
        _id: string;
        _label: string;
    },
    object_id: string;
    object_class: string;
    _id: string;
    _label: string;
}

export interface EventByIdDay  {
    eventday_id: string;
    day_type: {
        _id: string;
        _label: string;
    },
    daystart: string;
    dayend: string;
    alternate_day_info: string;
    label: string;
    location: {
        location_id: string;
        location_label: string;
        extra: string;
        cost: string;
        address: {
            address_id: string;
            address_text: string;
            street: string;
            city: string;
            dependentLocality: string;
            state: {
                _id: string;
                _label: string;
            },
            zip: string;
            country: {
                _id: string;
                _label: string;
            },
            latitude: null,
            longitude: null,
            layer: null,
            _id: string;
            _label: string;
        },
        lat: string;
        lng: string;
        parking_info: string;
        locationAddressDisplayString: string;
        _id: string;
        _label: string;
    },
    parking_info: string;
    capacity_table: BooleanString;
    capacity_company: BooleanString;
    hide_on_regform: {
        _id: 'yes' | 'no';
        _label: string;
    },
    hide_from_students: {
        _id: BooleanString;
        _label: string;
    },
    hide_pricing: {
        _id: BooleanString;
        _label: string;
    },
    orderby: string;
    displayStartTime: string;
    displayEndTime: string;
    displayStartDate: string;
    displayEndDate: string;
    maps: any[];
    _id: string;
    _label: string;
}

export interface EventRegistrationsResponse {
    className: string;
    columns: ColumnMetadata[];
    models: EventRegistration[];
    page: number;
    perPage: number;
    total: number;
}

export interface EventRegistration {
    approved: string;
    booths: any[];
    cancelled_flag: string;
    contact: {
        _id: number,
        _label: string;
    };
    contact_id: string;
    days: {
        dayend: string;
        daystart: string;
        hide_from_students: BooleanString;
        _id: string;
        _label: string;
    }[];
    email: string;
    employer: {
        _id: number,
        _label: string;
    };
    employer_id: string;
    employer_type?: {
        _id: number,
        _label: string;
    };
    fullname: string;
    industry?: {
        _id: number,
        _label: string;
    }[];
    logo: string;
    name: string;
    registered_on: string;
    registration_id: string;
    reps_count?: number;
}

export interface EventFilters {
    fields: EventFilter[];
}

export interface EventFilter {
    field_blurb: string;
    field_key: string;
    field_name: string;
    field_type: string;
    field_type_options: any;
}
