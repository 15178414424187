import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
    selector: '[dropdown-filter]',
    templateUrl: './dropdown-filter.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DropdownFilterComponent {
    @Input() formGroup;
    @Input() template;
    @Input() autoSave = false;
    @Input() alwaysShowActionButtons = false;
    @Input() hideChipList = false;
    @Input() alwaysShowDropdownList = false;
    @Input() styleClass = '';
    @Input()
    get control() {
        return this._control;
    }
    set control(control) {
        this._control = {
            ..._.cloneDeep(control),
            hideChipList: this.hideChipList,
            alwaysShowDropdownList: this.alwaysShowDropdownList
        };
        this.resetAvailability;

        if (this.autoSave) {
            if (!(this.control.type === 'checkbox' && this.control.options instanceof Observable)) {
                this.activatedAutoSaveWhenClose = true;
            }
        }
    }
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
    @Output() onApplyFilters: EventEmitter<any> = new EventEmitter();
    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    public displayClearBtn$ = new BehaviorSubject(false);

    _control;
    private prevValue;
    private isSubmitting: boolean = false;
    private activatedAutoSaveWhenClose: boolean = false;
    private valueChangesSubscription: Subscription;
    public available: boolean = true;
    get resetAvailability() {
        const available = _.clone(this.available);
        if (this.available) {
            this.available = false;
            setTimeout(() => {
                this.available = true;
            }, 500);
        }
        return available;
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    isValueChanged(value = this.formGroup.get(this.control.id).value): boolean {
        return !_.isEqualWith(value, this.prevValue, (a, b) => { return (_.isEmpty(a) && !_.isNumber(a)) && (_.isEmpty(b) && !_.isNumber(b)) ? true : undefined });
    }

    autoSaveWhenChange() {
        this.unsubscribe();
        this.valueChangesSubscription = this.formGroup.get(this.control.id).valueChanges.subscribe(res => {
            this.prevValue = res;
            if (this.dropdown.isOpen() && this.resetAvailability) {
                this.filterSubmit({ [this.control.id]: res });
            }
        });
    }

    toggled(isOpen) {
        if (isOpen) {
            if (this.control.type === 'checkbox' && this.control.options instanceof Observable) {
                this.control.options.subscribe(options => {
                    if (options.length == 1) {
                        this.prevValue = this.prevValue ? this.prevValue : this.formGroup.get(this.control.id).value;
                        this.autoSaveWhenChange();
                    }
                });
            } else {
                this.prevValue = this.formGroup.get(this.control.id).value;
                this.displayClearBtn$.next(this.prevValue);
                this.valueChangesSubscription = this.formGroup.get(this.control.id).valueChanges.subscribe(value => {
                    this.displayClearBtn$.next(this.hasValue(value));
                });
            }
        } else {
            if (this.isValueChanged()){
                if (this.activatedAutoSaveWhenClose) {
                    this.valueSubmit();
                } else if (!this.isSubmitting) {
                    this.onCancel.emit(this.control);
                }
            }

            this.unsubscribe();
        }
    }

    public clear(): void {
        this.control.value = undefined;
        this.formGroup.get(this.control.id).setValue(undefined);
        this.displayClearBtn$.next(false);
    }

    filterSubmit(value = undefined): void {
        this.valueSubmit(value);
        this.dropdown.close();
    }

    valueSubmit(value = this.formGroup.value): void {
        this.control.value = this.formGroup.get(this.control.id).value;
        this.isSubmitting = true;
        this.onApplyFilters.emit(value);
    }

    unsubscribe(): void {
        if (this.valueChangesSubscription && !this.valueChangesSubscription.closed) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    private hasValue(value: any): boolean {
        return Array.isArray(value) ? !!value.length : typeof value !== 'undefined';
    }
}
