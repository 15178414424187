import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { FormConfig } from '@symplicity/syng';
import { Validators } from '@angular/forms';
import { PicklistValue, convertSoDependencies } from '@symplicity/syng';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
declare var window: any;

@Injectable({ providedIn: 'root' })
export class FormConfigService {
    protected formConfigs: {} = {};
    private asyncOptionsWidgets = ['ngselect', 'multiselect', 'hierpicklist', 'select', 'checkbox', 'picklist'];
    private formSubscriptionMap: Map<string, Subscription> = new Map();

    constructor(
        protected http: HttpClient
    ) {}

    public getForm (resource: string, templateName: string = '', objectId: string = '', queryPrams: {}, args?: any): Observable<any> {
        let template = templateName !== '' ? templateName : resource;
        let pKey = (args && args.pKey) ? args.pKey : null;
        if (!this.formConfigs.hasOwnProperty(template)) {
            const configSubject = new ReplaySubject<any>();
            this.cancelFetch(template);
            let fetchFormSubscription = this.fetchForm(resource, objectId, queryPrams).subscribe({
                next: (formStructure) => {
                    this.formConfigs[template] = formStructure;
                    let config = this.setFormConfig(formStructure, resource, objectId, pKey, template);
                    configSubject.next(config);
                },
                error: (err) => {
                    this.formSubscriptionMap.delete(template);
                },
                complete: () => {
                    this.formSubscriptionMap.delete(template);
                }
            });
            this.formSubscriptionMap.set(template, fetchFormSubscription);
            return configSubject;
        } else {
            let config = this.setFormConfig(this.formConfigs[template], resource, objectId, pKey, template);
            return new BehaviorSubject(config);
        }
    }

    deleteTemplate(template: string) {
        delete this.formConfigs[template];
    }

    cancelFetch(template: string) {
        if (this.formSubscriptionMap.has(template)) {
            this.formSubscriptionMap.get(template)?.unsubscribe();
            this.formSubscriptionMap.delete(template);
        }
    }

    protected fetchForm (resource: string = '', id: string = '', queryParams: {}) {
        let apiVersion = queryParams['apiVersion'] || 'v3';
        let headers = {};
        if (id) {
            queryParams['object_id'] = id;
        }
        if (window.SUB_SYSTEM) {
            headers['x-requested-system-user'] = window.SUB_SYSTEM;
        }
        return this.http.get(`/api/${apiVersion}/${resource}/form-structure`, {params: queryParams, headers});
    }

    static transformPicklist(opts) {
        return opts.map((pick) => {
            return new PicklistValue(
            pick['id'],
            pick['value'],
            pick['disabled']);
        });
    }

    protected getResolveFieldType(fieldStructure, resourceName) {
        let type = 'static';
        switch (fieldStructure.field_type) {
            case 'radio':
            case 'radiogroup':
            case 'yesno':
            case 'onoff':
                type = 'radio';
                break;
            case 'checkbox':
            case 'monocheck':
                type = 'checkbox';
                break;
            case 'select':
                type = 'select';
                break;
            case 'picklist':
            case 'picklist_multi':
            case 'hierpicklist':
                type = 'hierpicklist';
                break;
            case 'date':
            case 'datedynamicwidget':
                type = 'datepicker';
                break;
            case 'datetime':
                type = 'datetimepicker';
                break;
            case 'relation':
                if (fieldStructure.field_type_options.relation_pick_widget == 'SelectWithSearch') {
                    type = 'aclookup';
                }
                if (fieldStructure.field_type_options.form_view) {
                    type = 'relation';
                }
                if (fieldStructure.field_type_options.relation_pick_widget == 'SelectList') {
                    type = 'select';
                }
                break;
            case 'text':
            case 'url':
            case 'number':
            case 'email':
                type = 'input';
                break;
            case 'textarea':
                type = 'textarea';
                break;
            case 'graphicalrating':
                type = 'rating';
                break;
            case 'tinymce':
                type = 'richtext';
                break;
            case 'location':
                type = 'location';
                break;
            case 'file':
                type = 'fileUpload';
                break;
        }
        if (fieldStructure.field_type_options.read_only) {
            type = 'static';
        }
        return type;
    }

    protected setFormConfig(formStructure, resourceName, resourceId, pKey, template) {
        const config = {
            title: resourceName,
            resourceID: resourceId,
            templateName: formStructure.template_name,
            pKey: pKey,
            useGroupedForm: true,
            controlGroups:[],
            name: template || resourceName,
            alertMessage: formStructure.alertMessage
        };
        formStructure.fields.forEach((group) => {
            if (group.group_fields) {
                let controlGroup = {
                    groupId : group.group_id,
                    groupTitle : group.group_title,
                    controls : []
                };
                group.group_fields.forEach(fieldStructure => {
                    let field = this.assembleFieldConfig(fieldStructure, resourceName);
                    controlGroup.controls.push(field);
                });
                config.controlGroups.push(controlGroup);
            }
        });
        let formConfig = new FormConfig(config);
        convertSoDependencies(formConfig, 'soDependencies');
        return formConfig;
    }

    assembleFieldConfig(fieldStructure, resourceName) {
        if (!fieldStructure.field_type_options) {
            fieldStructure.field_type_options = {};
        }
        let field: any = {
            id: fieldStructure.field_key,
            type: this.getResolveFieldType(fieldStructure, resourceName),
            label: fieldStructure.field_name,
            name: fieldStructure.field_key,
            value: fieldStructure.field_value,
            blurb: fieldStructure.field_blurb,
            validators: undefined,
            options: undefined,
            config: undefined,
            hideField: undefined,
            readOnly: fieldStructure.field_type_options?.read_only,
            soWidget: fieldStructure.field_type,
            inputType: undefined,
            isMultiSelection: undefined,
            multiple: undefined,
            chipList: undefined,
            chipLabelAsValue: fieldStructure.field_type_options.chipLabelAsValue || false,
            displayBlurbOnRO:fieldStructure.field_type_options.show_blurb_in_read_only || false,
            triggerEvent: undefined,
            clearButton: fieldStructure.field_type_options.clearButton,
            api: this.getFieldApi(fieldStructure),
            dropdownItemTemplate: fieldStructure.field_type_options.dropdownItemTemplate,
            defaultValue: fieldStructure.field_type_options.default_value,
            showActiveFilters: fieldStructure.showActiveFilters,
            emptyPicklist: !fieldStructure.field_type_options.picklist_options || fieldStructure.field_type_options.picklist_options.filter(opt => opt.id != '').length == 0 ? true : false,
            soDependencies: fieldStructure.field_dependencies || null
        };
        if (fieldStructure.field_type_options?.relation_class === 'file') {
            field.soWidget = 'file';
        }
        if (fieldStructure.field_type === 'hidden' || fieldStructure.field_type_options.hidden) {
            field.hideField = true;
        }
        if (fieldStructure.field_type_options.triggerEvent) {
            field.triggerEvent = fieldStructure.field_type_options.triggerEvent.bind(this);
        }

        if (field.type === 'input' && ['url', 'number', 'email'].indexOf(field.soWidget) > -1) {
            field.inputType = field.soWidget;
        }

        if (fieldStructure.field_html_options?.maxlength) {
            field.maxLength = fieldStructure.field_html_options.maxlength;
        }

        if (field.type === 'hierpicklist') {
            field = this.assignField('parentSelectable', fieldStructure.field_type_options, field);
            field = this.assignField('onChangeCallback', fieldStructure.field_type_options, field);
        }

        field.multiple = fieldStructure.field_type_options && fieldStructure.field_type_options.multiple ? true : false;

        if (field.type === 'relation') {
            let objectId = fieldStructure.field_value || '';
            field.config = this.getForm(resourceName, fieldStructure.field_type_options.form_view, objectId, {form:fieldStructure.field_type_options.form_view, class:fieldStructure.field_type_options.relation_class});

            if (fieldStructure.field_type_options) {
                let opt = fieldStructure.field_type_options;
                if (['n+1', 'remote'].includes(opt.relation_subform_type)) {
                    field.type = 'n1';
                    let params = [
                        'relation_subform_max_count',
                        'n1_addbutton_label',
                        'n1_deletebutton_label',
                        'n1_no_delete',
                        'n1_render_empty'
                    ]
                    params.forEach(param => {
                        if (opt[param]) {
                            field[param] = opt[param];
                        }
                    })
                }
            }
        }

        if (field.type === 'fileUpload') {
            if (fieldStructure.field_type_options) {
                let opt = fieldStructure.field_type_options;
                let params = {
                    uploadAPI: '/api/v3/files/chunk-file-upload',
                    maxFileSize: window.MAX_DOCUMENT_FILESIZE_UPLOAD
                }
                field = _.extend(field, params)
                field.onDownload = (file) => {
                    if (file.file_id) {
                        window.open(`/api/v2/files/${file.file_id}`)
                    }
                }
                Object.keys(params).forEach(key => {
                    if (opt[key]) {
                        field[key] = opt[key];
                    }
                })
            }
        }
        if (fieldStructure.field_type_options.required) {
            field.validators = Validators.required
        }
        if (fieldStructure.field_type_options.picklist_options) {
            if (field.soWidget === 'hierpicklist' || fieldStructure.field_type_options.hierarchical) {
                field.options = fieldStructure.field_type_options.picklist_options;
            } else {
                field.options = FormConfigService.transformPicklist(fieldStructure.field_type_options.picklist_options);
            }
            if (this.asyncOptionsWidgets.indexOf(field.type) > -1) {
                field.options = new BehaviorSubject(field.options).asObservable();
            }
        }

        if (field.type === 'location' && !_.isUndefined(fieldStructure.field_type_options.chipList)) {
            field.chipList = fieldStructure.field_type_options.chipList;
        }
        if (field.type === 'input' || field.type === 'location' ) {
            if (fieldStructure.field_type_options.placeholder) {
                field['placeholder'] = fieldStructure.field_type_options.placeholder;
            }
            if (fieldStructure.field_type_options.ariaLabel) {
                field['ariaLabel'] = fieldStructure.field_type_options.ariaLabel;
            }
            if (fieldStructure.field_type_options.blurbSrOnly) {
                field['blurbSrOnly'] = fieldStructure.field_type_options.blurbSrOnly;
            }
        }
        if (field.soWidget === 'tinymce' && fieldStructure.field_type_options.hasOwnProperty('tinyMceOptions')) {
            field.options = fieldStructure.field_type_options.tinyMceOptions;
        }
        if (fieldStructure.field_type === 'date' && fieldStructure.field_type_options?.month_list) {
            field.options = fieldStructure.field_type_options;
        }

        if (field.type === 'datepicker' && fieldStructure.field_type_options?.default_now) {
            field.defaultValue = moment().format('YYYY-MM-D');
        }

        return field;
    }

    getFieldApi(fieldStructure) {
        if (fieldStructure.field_type_options.api) {
            return fieldStructure.field_type_options.api;
        }
        if (fieldStructure.field_type_options?.autocomplete_lookup_script) {
            let nameQuery: string = fieldStructure.field_type_options?.name || '';
            if (nameQuery) {
                return `${fieldStructure.field_type_options?.autocomplete_lookup_script}?name=${nameQuery}`;
            }
            return `${fieldStructure.field_type_options?.autocomplete_lookup_script}`;
        }
    }

    preparePicklistValuesForSave(formData, formConfig) {
        _.each(formConfig.controlGroups, (group) => {
            this.picklistValuesForSave(formData, group, true);
        });
    }

    picklistValuesForSave(formData, controlGroup, isControlGroup = false) {
        _.each(controlGroup.controls, (field) => {
            if (this.asyncOptionsWidgets.indexOf(field.type) > -1) {
                if (Array.isArray(formData[field.name]) && formData[field.name].length) {
                    if (field.multiple) {
                        if (formData[field.name][0].hasOwnProperty('id')) {
                            formData[field.name] = _.map(formData[field.name], 'id');
                        } else if (formData[field.name][0].hasOwnProperty('_id')) {
                            formData[field.name] = _.map(formData[field.name], '_id');
                        }
                    } else if (formData[field.name][0].hasOwnProperty('id')) {
                        formData[field.name] = formData[field.name][0].id;
                    } else if (formData[field.name][0].hasOwnProperty('_id')) {
                        formData[field.name] = formData[field.name][0]._id;
                    } else if (formData[field.name].length == 1) {
                        formData[field.name] = formData[field.name][0];
                    }
                } else if (formData[field.name] && typeof formData[field.name] === 'object' && formData[field.name].hasOwnProperty('id')) {
                    formData[field.name] = formData[field.name].id;
                }
            }
            if (field.type === 'relation') {
                if (isControlGroup) {
                    this.preparePicklistValuesForSave(formData[field.name], field.config);
                } else {
                    this.picklistValuesForSave(formData[field.name], field.config);
                }
            }
            if (field.type === 'richtext' && formData[field.name] && formData[field.name].hasOwnProperty('html')) {
                formData[field.name] = formData[field.name].html;
            }
        });
    }

    assignField(key: string, origin, target) {
        if (origin.hasOwnProperty(key)) {
            target[key] = origin[key];
        }
        return target;
    }
}
