<div
    ngbDropdown
    #dropdown="ngbDropdown"
    class="btn-group customSelect dynamic-form-filter-widget"
    [ngClass]="styleClass"
    (openChange)="toggled($event)"
>
    <button class="btn btn_minimal filter-dropdown-btn more-filter-dropdown-btn" [id]="'listFilter-category-' + control.id" ngbDropdownToggle>
        <span class="filter-text">{{control.label | translate}}</span>
        <i class="icn-chevron_down"></i>
    </button>
    <div ngbDropdownMenu [attr.aria-labelledby]="'listFilter-category-' + control.id" class="filter-grid" [ngClass]="{'autoSave': autoSave}">
        <div class="field last">
            <div class="field-widget">
                <div *ngIf="available" class="filter-sub-menu">
                    <div
                        dynamicField
                        [group]="formGroup"
                        [config]="control"
                        [value]="control.value"
                    ></div>
                </div>

                <div *ngIf="!autoSave || alwaysShowActionButtons" class="filter-btns border-top">
                    <button *ngIf="displayClearBtn$ | async" class="btn_text-link secondary-text" (click)="clear()">{{'misc.Clear' | translate}}</button>
                    <button type="submit" class="btn btn_primary no-margin pull-right" (click)="filterSubmit(dropdown)">{{'misc.Apply' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
