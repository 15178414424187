<div class="login-wrapper login-main has-reg user_type">
  <div class="error-page">
    <div class="mainbody">
      <div class="error-background illustration"></div>
      <p class="sorry">{{"misc.Sorry! We couldn't find the page you were looking for." | translate}}</p>
      <p class="orbit">{{errorMessage | translate}}</p>
      <div class="buttonbar">
        <a class="btn btn_primary" href="/" class="input-button btn btn_primary">{{"misc.Go Home" | translate}}</a>
        <a class="btn btn_default" href="javascript:void(0)" (click)="goBack();" class="input-button btn btn_secondary">{{"misc.Go Back" | translate}}</a>
      </div>
    </div>
  </div>
</div>