<ng-container *ngIf="formConfig && formGroup && fieldControls.length">
    <div #searchform class="search-form-filters form form-editmode flexbox flex-column dynamic-form-filter-widget" role="search" [ngClass]="styleClass">
        <div class="filter-fields">
            <div class="field last flexbox">
                <div class="search-filter filter-grid field-widget full_width">
                    <ng-container *ngFor="let control of fieldControls; index as idx">
                        <div class="field input-wrapper" [ngClass]="filters[idx].styleClass">
                            <div
                                dynamicField
                                [group]="formGroup"
                                [config]="control"
                                [value]="control.value"
                            ></div>
                        </div>
                    </ng-container>
                </div>
                <div class="search-filter-button-container flexbox">
                    <button type="submit" class="btn btn_default btn-sticky" (click)="submitFormFilters()">{{'misc.Search' | translate}}</button>
                    <button class="btn btn_default hide display-mobile-inline-block" [ngClass]="mobileClass" (click)="openMobileFilterWindow()" [attr.aria-label]="'misc.More Filters' | translate">
                        <i class="icn-custom_setting no-margin lg-text"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="onlyMobileFilters">
    <button class="btn btn_default hide display-mobile-inline-block" [ngClass]="mobileClass" (click)="openMobileFilterWindow()" [attr.aria-label]="'misc.More Filters' | translate">
        <i class="icn-custom_setting no-margin lg-text"></i>
    </button>
</ng-container>
