import {Injectable} from '@angular/core';
import {FormConfig} from '@symplicity/syng';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ListDynamicFormFilterService {
    getDropdownFiltersData(formConfig: FormConfig, filterSet): any {
        let filters = [];
        filterSet.forEach((item) => {
            if (_.isArray(item.field)) {
                const filter = new FilterControls({
                    label: item.label,
                    isGroup: true,
                    styleClass: item.styleClass ? item.styleClass : null,
                    onShow: item.onShow || null
                });
                const singleFields = item.field.filter(f => !_.isArray(f.field));
                const groupFields = item.field.filter(f => _.isArray(f.field));

                if (singleFields.length) {
                    filter['control'] = _.filter(formConfig.controls, ctrl => _.some(singleFields, ['field', ctrl.id]));
                }
                if (groupFields) {
                    filter['group'] = [];
                    groupFields.forEach((field,i) => {
                        filter['group'].push({
                            label: field.label,
                            isGroup: true,
                            styleClass: field.styleClass ? field.styleClass : null,
                            control: _.filter(formConfig.controls, ctrl => _.some(field.field, ['field', ctrl.id]))
                        })
                    });
                }
                filters.push(filter);
            } else {
                const idx = _.findIndex(formConfig.controls, ctrl => ctrl.id === item.field);
                filters.push({
                    label: item.label || formConfig.controls[idx].label,
                    isGroup: false,
                    control: formConfig.controls[idx],
                    styleClass: item.styleClass ? item.styleClass : null,
                    autoSave: item.autoSave,
                    options: item.options || {}
                });
            }
        });
        return filters;
    }
}

export class FilterOptions {
    styleClass?: string = '';
    label?: string;
    autoSave?: boolean;
    options?: object;
    onShow?: Function;
    constructor(init?:Partial<FilterField>) {
        Object.assign(this, init);
    }
}

export class FilterField extends FilterOptions {
    field: string | FilterField[] = ''; /* Add a Control ID in FormConfig */
    constructor(init?:Partial<FilterField>) {
        super();
        Object.assign(this, init);
    }
}

export class FilterControls extends FilterOptions {
    isGroup: boolean = false;
    control?: any[];
    group?: FilterControls[];
    constructor(init?:Partial<FilterControls>) {
        super();
        Object.assign(this, init);
    }
}
