import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormConfig } from '@symplicity/syng';
import { DateLabel, EventDate, EventDateService, FormConfigService } from '@csm/shared';
import { ValkyrieBackendService } from '@symplicity/syng';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
    EventTypes,
    AttendeeTypes,
    EVENT_LIST_RESPONSE,
    EVENT_REGISTRATIONS_RESPONSE,
    EVENT_FILTERS_RESPONSE
} from './events.constants';
import {
    EventsParams,
    EventsResponse,
    EventStatus,
    EventByIdResponse,
    EventModel,
    EventRegistrationsResponse,
    EventsRegistrationParams,
    EventRegistration,
    EventFilters,
    BooleanString
} from './events.types';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';

const HEADERS: Record<string, string> = {
    'x-requested-system-user': 'events'
};

@Injectable()
export class EventsService {
    public simpleFiltersIds = ['position_types'];
    private cachedEvent: EventByIdResponse;
    private cachedEventRegistration: EventRegistrationsResponse;
    private cachedEventFiltersForm = new Map<string, FormConfig>();

    constructor(
        private backendService: ValkyrieBackendService,
        private eventDateService: EventDateService,
        private translateService: TranslateService,
        private window: Window,
        private router: Router,
        private formConfigService: FormConfigService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.simpleFiltersIds.push(this.window.ngData.isLawCsm ? 'employer_type' : 'industry');

        const locale = this.window.LOCALE;
        const tz = this.window.USER_TZ;

        moment.locale(locale);
        moment.tz.setDefault(tz);
    }

    public getEvents(params: EventsParams): Observable<EventsResponse> {
        return this.backendService.getAll('external-events', 'v3', {
            page: params.page,
            perPage: params.perPage,
            status: params.view === 'past' ? 'passed' : '',
            sort: params.view === 'past' ? '!_dayend,label' : '_daystart,label'
        }, HEADERS).pipe(
            map(response => ({
                ...response,
                models: response.models.map(model => ({
                    ...model,
                    overview: this.getEventOverview(model.overview),
                    status: (model.status as string).toLowerCase()
                }))
            })),
            catchError(() => of(EVENT_LIST_RESPONSE))
        );
    }

    public getEventById(eventId: string): Observable<EventByIdResponse> {
        return this.cachedEvent && this.cachedEvent.event_id === eventId
            ? of(this.cachedEvent)
            : this.backendService.getOne(eventId, 'external-events', 'v3', {
                publicpage: true
            }).pipe(
                map(event => ({
                    ...event,
                    status: (event.status as string).toLowerCase()
                })),
                tap(response => this.cachedEvent = response),
                catchError(error => {
                    if (error.status === 404) {
                        return this.backendService.getAll('external-events', 'v3', { nickname: eventId }).pipe(
                            switchMap(response => {
                                const model = response.models[0];

                                if (model) {
                                    return from(this.router.navigateByUrl(`/events/${model.event_id}`)).pipe(
                                        map(() => EMPTY)
                                    );
                                } else {
                                    return from(this.router.navigateByUrl('/events/upcoming')).pipe(
                                        map(() => EMPTY)
                                    );
                                }
                            })
                        );
                    } else {
                        return from(this.router.navigateByUrl('/events/upcoming')).pipe(
                            map(() => EMPTY)
                        );
                    }
                })
            );
    }

    public getEventRegistrations(eventId: string, params: EventsRegistrationParams, useCache = false): Observable<EventRegistrationsResponse> {
        return useCache && this.cachedEventRegistration && this.cachedEventRegistration.className === `event_${eventId}`
            ? of(this.cachedEventRegistration)
            : this.backendService.getAll(`external-events/${eventId}/registrations`, 'v3', {
                ...params,
            }, HEADERS).pipe(
                tap(response => {
                    if (useCache) this.cachedEventRegistration = response;
                }),
                catchError(() => of(EVENT_REGISTRATIONS_RESPONSE(eventId)))
            );
    }

    public getEventDateLabel(view: 'list' | 'event', event: EventModel | EventByIdResponse): DateLabel {
        const { virtual_start, virtual_end, status } = event;
        const eventDate = new EventDate({
            dates: []
        });

            if (view === 'list') {
                (event as EventModel).days.forEach(day => eventDate.dates.push({
                    start: day.dates.dayStart,
                    end: day.dates.dayEnd
                }));
            } else if (event.type._id === EventTypes.VirtualCareerFair && virtual_start) {
                eventDate.isVirtualFair = true;
                eventDate.dates.push({
                    start: virtual_start,
                    end: virtual_end
                });
            } else {
                (event as EventByIdResponse).day.forEach(day => eventDate.dates.push({
                    start: day.daystart,
                    end: day.dayend
                }));
            }

        return !eventDate.dates.length || status === 'unknown'
            ? this.eventDateService.getHiddenDaysFormat()
            : status === 'passed'
                ? this.eventDateService.getPastEventDate(eventDate)
                : this.eventDateService.getUpcomingEventDate(eventDate, view === 'list');
    }

    public getEventRegistrationAttendingLabel(count: number | undefined, eventStatus: EventStatus): string {
        const translationKey = eventStatus === 'passed'
            ? 'event employers.representatives attended'
            : 'event employers.representatives attending';

        return this.translateService.instant(translationKey, {
            count: count || 0
        });
    }

    public getEventRegistrationDatesLabel(registration: EventRegistration, eventStatus: EventStatus): string {
        const translationKey = eventStatus === 'passed'
            ? 'event employers.Attended'
            : 'event employers.Attending';
        const days = registration.days;
        const labels: string[] = [];

        days
            .filter(day => day.hide_from_students !== '1')
            .forEach(day => {
                labels.push(
                    this.eventDateService.isSame(day.daystart.substring(0,10), day.dayend.substring(0,10), 'day')
                        ? this.eventDateService.convertDate(day.daystart, this.eventDateService.getConfigDateFormat(null, true))
                        : `${this.eventDateService.convertDate(day.daystart, this.eventDateService.getConfigDateFormat(null, true))} - ${this.eventDateService.convertDate(day.dayend, this.eventDateService.getConfigDateFormat(null, true))}`
                );
            });

        return `${this.translateService.instant(translationKey)} ${labels.join(', ')}`;
    }

    public getEventFilters(eventId: string): Observable<EventFilters> {
        return this.backendService.getAll(`external-events/${eventId}/filter-structure`, 'v3', {}, HEADERS).pipe(
            catchError(() => of(EVENT_FILTERS_RESPONSE))
        );
    }

    public getEventFiltersFormConfig(eventId: string): Observable<FormConfig> {
        return this.cachedEventFiltersForm.has(eventId)
            ? of(this.cachedEventFiltersForm.get(eventId))
            : this.getEventFilters(eventId).pipe(
                map(response => {
                    const template = 'career-fairs';
                    const config = {
                        title: template,
                        resourceId: eventId,
                        useGroupedForm: false,
                        controls: [],
                        name: template,
                        value: null
                    };

                    response.fields.forEach(fieldObj =>
                        config.controls.push(
                            this.formConfigService.assembleFieldConfig(fieldObj, template)
                        )
                    );

                    const formConfig = new FormConfig(config);

                    formConfig.controls = formConfig.controls.map(control => {
                        if (control.type === 'textarea' || control.type === 'richtext') {
                            control.soWidget = 'text';
                            control.type = 'input';
                        }

                        control.isSimpleFilter = (
                            this.simpleFiltersIds.includes(control.id) &&
                            (control.soWidget === 'picklist' || control.soWidget === 'checkbox')
                        );

                        return control;
                    });

                    return formConfig;
                }),
                tap(response => this.cachedEventFiltersForm.set(eventId, response))
            );
    }

    public createFormGroup(controls: any[], filterData: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});

        controls.map(control => {
            if (filterData[control.name]) {
                if (!control.multiple || Array.isArray(filterData[control.name])) {
                    control.value = filterData[control.name];
                } else {
                    control.value = new Array(filterData[control.name]);
                }
            } else {
                control.value = undefined;
            }

            formGroup.addControl(control.name, new UntypedFormControl(control.value, control.validators));
        });

        return formGroup;
    }

    public registerAsAnEmployer(eventId: string, disableAutofill: BooleanString): void {
        this.window.location.href = `/events/${eventId}/regform?login_modal=${disableAutofill === '1' ? 0 : 1}`;
    }

    private getEventOverview(overview: string): string {
        if (!overview) {
            return '';
        }

        const overviewHTML = this.document.createElement('div');
        overviewHTML.innerHTML = overview;

        let firstParagraph = '';

        overviewHTML.childNodes.forEach(childNode => {
            if (childNode.nodeName === 'P' && !firstParagraph) {
                firstParagraph = childNode.textContent;
            }
        });

        return firstParagraph;
    }
}
