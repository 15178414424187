import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventDateService } from '../services/event-date.service';
import { RegularFairDay, RegularFairDayItem, VirtualFairDay, VirtualFairDayItem } from './when-and-where.model';

@Component({
    selector: 'when-and-where',
    templateUrl: './when-and-where.component.html'
})
export class WhenAndWhereComponent {
    @Input()
    public isVirtualFair = false;

    @Input()
    public set regularFairDays(regularFairDays: RegularFairDay[]) {
        this.regularFairDayItems = regularFairDays.map(day => {
            const { id, start, end, isSingleDay, date, label, addressLabel, mapUrl, address, boothmapCount, parkingInformation } = day;

            const timeFormat = isSingleDay ? this.eventDateService.getConfigTimeFormat() : `${this.eventDateService.getConfigDateFormat(null, true)} ${this.eventDateService.getConfigTimeFormat()}`;
            const startTimeLabel = this.eventDateService.convertDate(start, timeFormat);
            const endTimeLabel = this.eventDateService.convertDate(end, timeFormat);
            const time = startTimeLabel === endTimeLabel ? startTimeLabel : `${startTimeLabel} - ${endTimeLabel}`;

            return new RegularFairDayItem({
                id,
                month: this.eventDateService.convertDate(start, 'MMM'),
                day: this.eventDateService.convertDate(start, 'D'),
                date,
                label,
                time,
                addressLabel,
                mapUrl,
                address,
                boothmapCount,
                parkingInformation
            });
        });
    }

    @Input()
    public set virtualFairDay(virtualFairDay: VirtualFairDay) {
        if (!this.isVirtualFair) {
            return;
        }

        const { start, end, blurb } = virtualFairDay;

        const startDayAndMonth = this.eventDateService.convertDate(start, this.eventDateService.getConfigDateFormat(null, true));
        const endDayAndMonth = this.eventDateService.convertDate(end, this.eventDateService.getConfigDateFormat(null, true));
        const startDay = this.eventDateService.convertDate(start, 'D');
        const endDay = this.eventDateService.convertDate(end, 'D');
        const startTime = this.eventDateService.convertDate(start, this.eventDateService.getConfigTimeFormat('SHORT'));
        const endTime = this.eventDateService.convertDate(end, this.eventDateService.getConfigTimeFormat('SHORT'));

        this.virtualFairDayItem = new VirtualFairDayItem({
            month: this.eventDateService.convertDate(start, 'MMM'),
            day: startDay,
            label: `${startDayAndMonth}, ${startTime} - ${startDay !== endDay ? `${endDayAndMonth}, ` : ''} ${endTime}`,
            blurb
        });
    }

    @Input()
    public mapSrc: string;

    @Input()
    public boothmapString = 'misc.View booth map';

    @Input()
    public parkingString = 'misc.Parking Information';

    @Output()
    public readonly openBoothmapModal = new EventEmitter<RegularFairDayItem>();

    @Output()
    public readonly openParkingInfoModal = new EventEmitter<RegularFairDayItem>();

    public regularFairDayItems: RegularFairDayItem[] = [];
    public virtualFairDayItem: VirtualFairDayItem;

    constructor(private eventDateService: EventDateService) {}

    public onOpenBoothmapModal(day: RegularFairDayItem): void {
        this.openBoothmapModal.emit(day);
    }

    public onOpenParkingInfoModal(day: RegularFairDayItem): void {
        this.openParkingInfoModal.emit(day);
    }
}
