<div class="floating_content">
    <div class="form-col flexbox flex-column align-items-center justified-content-center text-center">
        <img *ngIf="options.imageSrc"
            role="presentation"
            [src]="options.imageSrc"
            [alt]="options.imageAlt ? (options.imageAlt | translate) : ''"
        />

        <h2
            *ngIf="options.title"
            class="space-top-md space-bottom-sm"
        >
            {{ options.title | translate }}
        </h2>

        <p
            *ngIf="options.message"
            class="secondary-text body-small space-bottom-lg"
        >
            {{ options.message | translate }}
        </p>

        <ng-container *ngIf="options.buttonLabel && options.buttonRoute">
            <a
                *ngIf="options.isButtonRouteExternal"
                class="btn btn_primary no-margin"
                [href]="options.buttonRoute"
            >
                {{ options.buttonLabel | translate }}
            </a>
            <a
                *ngIf="!options.isButtonRouteExternal"
                class="btn btn_primary no-margin"
                [routerLink]="options.buttonRoute"
            >
                {{ options.buttonLabel | translate }}
            </a>
        </ng-container>
    </div>
</div>
