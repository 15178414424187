import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EventDateService } from "@csm/shared";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ValkyrieBackendService } from '@symplicity/syng'

@Component({
    selector: 'announcement-modal',
    templateUrl:'announcement-modal.component.html'
})
export class AnnouncementModal implements OnInit{

    @Input() announcementId: string;
    @Input() translationDomain: string;

    announcementData$: Observable<any>;

    constructor(
        public bsModalRef: BsModalRef,
        private dateService: EventDateService,
        private translate: TranslateService,
        private backendService: ValkyrieBackendService
    ){}

    ngOnInit() {
        this.announcementData$ = this.getAnnouncement(this.announcementId).pipe(
            map(res => {
                const dateLabel = this.dateService.convertDate(res.utc_date, this.dateService.getConfigDateFormat());
                res.postedAt = this.translate.instant("misc.Published on date", {date: dateLabel});
                if (res.banner_image) {
                    const background_position = res.banner_image.background_image_alignment ? res.banner_image.background_image_alignment._id.replace('_', ' ') : 'center center';
                    res.inlineStyles = {"background-image": "url(" + res.banner_image.background_image + ")", "background-position": background_position};
                }
                return res;
            })
        )
    }

    getAnnouncement(id) {
        return this.backendService.getOne(id, 'announcements', 'v2')
    }
}
