import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { registerLocaleData } from "@angular/common";
import { LOCALE_ID } from "@angular/core";
import locale_en from '@angular/common/locales/en';
import locale_ar_sa from '@angular/common/locales/ar-SA';
import locale_es_419 from '@angular/common/locales/es-419';
import locale_fr from '@angular/common/locales/fr';
import locale_pt from '@angular/common/locales/pt';
import {TranslateService} from '@ngx-translate/core';
import { STUDENT_WINDOW } from './students.types';

declare var window:any;

registerLocaleDataForAngular(window.LOCALE);

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    providers: [{provide: LOCALE_ID, useValue: window.LOCALE}]
})

export class AppComponent {
    constructor(private translate: TranslateService) {
        let languages = ['ar_sa', 'es_419', 'fr', 'pt_br'];
        if (languages.indexOf(window.LANGPACK) > -1) {
            translate.setDefaultLang(window.LANGPACK);
            translate.use(window.LANGPACK);
        } else {
            translate.setDefaultLang('en');
            translate.use('en');
        }

    }
}

function registerLocaleDataForAngular(locale)
{
    switch (locale) {
        case 'ar_sa':
            registerLocaleData(locale_ar_sa, 'ar_sa');
            break;
        case 'es_419':
            registerLocaleData(locale_es_419, 'es_419');
            break;
        case 'fr':
            registerLocaleData(locale_fr, 'fr');
            break;
        case 'pt_br':
            registerLocaleData(locale_pt, 'pt_br');
            break;
        default:
            registerLocaleData(locale_en, locale);
            break;
    }
}
