import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TextEditorService } from '../services/text-editor.service';

@Component({
    templateUrl: './error-page.component.html',
    selector: 'error-page',
    host: {
        class: 'error-page'
    }
})
export class ErrorPageComponent {
    public options: any;
    private routerData: any;

    constructor(
        private route: ActivatedRoute,
        private textEditorService: TextEditorService
    ) {
        this.routerData = this.route.snapshot.data || {};
        this.options = { ...this.options, ...this.routerData };

        if (this.routerData.textEditorConfig) {
            this.textEditorService.setConfig(this.routerData.textEditorConfig);
        }
    }
}
